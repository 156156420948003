import React from 'react';
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    fixedHeight: {
        height: 135,
    },
    button: {
        height: '100%',
        textTransform: '',
        fontSize: 40
    },
    icon: {
        fontSize: '61px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    wrapPage: {
        justifyContent: 'space-around'
    },
    root: {
        alignItems: 'center'
    },
    iconInfo: {
        marginRight: '16px',
        fontSize: '45px'
    },
    info: {
        display: 'flex',
        alignItems: 'center'
    },
    inline: {
        display: 'inline'
    },
    white: {
        color: "white"
    },
    titile: {
        color: "white"
    }
}));

export default function CustomerArea() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const {adress, zipCode, city} = useSelector(state => state.settings);
    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Bienvenue dans voter cabinet</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    {adress ? adress : <Skeleton className={classes.inline} /> },&nbsp;
                    {zipCode ? zipCode : <Skeleton className={classes.inline} />}&nbsp;
                    {city ? city : <Skeleton className={classes.inline} />}
                </Typography>
            </div>
            <div className="wrap-content-page">
                <Grid container spacing={3} className={classes.wrapPage}>
                    {/* Chart */}
                    <Grid item xs={5}>
                        <Paper className={fixedHeightPaper}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                href="#/documents"
                            >
                                Mes documents
                            </Button>
                        </Paper>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={5}>
                        <Paper className={fixedHeightPaper}>
                            <Paper className={fixedHeightPaper}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    href="#/demande"
                                >
                                    Faire une demande
                                </Button>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer banner={true} />
        </>
    )
}
