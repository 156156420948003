export const SETTINGS = 'SETTINGS';
export const INFO_PATIENTS = 'INFO_PATIENTS';
export const SET_MESSAGE_WITH_CODE = 'SET_MESSAGE_WITH_CODE';
export const TOKEN = 'TOKEN';
export const APPOINTMENTS = 'APPOINTMENTS';
export const APPOINTMENT_DETAIL = 'APPOINTMENT_DETAIL';
export const TYPE = 'TYPE';
export const TOKEN_MIKAJAKI = 'TOKEN_MIKAJAKI';
export const PROFESSIONS = 'PROFESSIONS';
export const IS_LOADING = 'IS_LOADING';
export const AUTHENTICATE = 'AUTHENTICATE';
export const DOCTORS = 'DOCTORS';
export const DOCTOR = 'DOCTOR';
export const AVAILABILITIES = 'AVAILABILITIES';
export const QUESTIONS = 'QUESTIONS';
export const FILES = 'FILES';
export const TYPE_FILES = 'TYPE_FILES';
export const RESET_APP = 'RESET_APP';
export const DATA_PRINT = 'DATA_PRINT';
export const CURRENT_PATIENT = 'CURRENT_PATIENT';
export const REASONS = 'REASONS';
export const CURRENT_REASON = 'CURRENT_REASON';
export const LOCATIONS = 'LOCATIONS';
export const CURRENT_LOCATION = 'CURRENT_LOCATION';
export const CATEGORIES = 'CATEGORIES';