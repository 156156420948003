import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Carousel from 'react-material-ui-carousel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { getReasonsAll } from '../utils/fetcher';
import Footer from '../components/Common/Footer';

const useStyles = makeStyles((theme) => ({
    img: {
        fontSize: '153px!important',
        color: 'white',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
    },
    marginAuto: {
        margin: 'auto',
    },
    caroussel: {
        maxWidth: '1147px',
        margin: 'auto',
        overflow: 'inherit',
        width: '100%',
    },
    white: {
        color: 'white',
    },
    text: {
        fontSize: 40,
        marginBottom: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconCheckboxChecked: {
        fontSize: 61,
        color: theme.palette.primary.main,
    },
    banner: {
        boxShadow: 'none',
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formControlLabel: {
        marginRight: 0,
    },
}));

export default function Specialities() {
    const classes = useStyles();
    const professions = useSelector((state) => (state.professions ? state.professions : []));
    const dispatch = useDispatch();

    const toggle = (id) => {
        dispatch(getReasonsAll(id));
    };

    const list = [];
    if (professions) {
        professions.forEach((item, key) => {
            if (key % 6 === 0) {
                list.push(professions.slice(key, key + 6));
            }
        });
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    Prendre mon rendez-vous
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Choisissez la spécialité concernée
                </Typography>
            </div>
            <div className="wrap-content-page">
                <div>
                    {list.length > 0 && (
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            indicators={false}
                            autoPlay={false}
                            className="caroussel"
                            navButtonsProps={{ className: 'CarouselButtons', disabled: list.length <= 1 }}
                            navButtonsWrapperProps={{ className: 'CarouselButtonsWrapper' }}
                        >
                            {list.map((elet, index) => (
                                <Card raised className={classes.banner} key={index}>
                                    <Grid container>
                                        {elet.map((item, key) => (
                                            <Grid className={classes.bloc} item xs={6} key={key}>
                                                <Card className={classes.card}>
                                                    <CardActionArea>
                                                        <CardContent className={classes.cardContent} onClick={() => toggle(item.id)}>
                                                            <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                                                {item.libelle}
                                                            </Typography>
                                                            <FormControlLabel
                                                                className={classes.formControlLabel}
                                                                control={<Checkbox name={`checked${item.id}`} value={item.id} />}
                                                            />
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Card>
                            ))}
                        </Carousel>
                    )}
                </div>
            </div>
            <Footer banner={false} />
        </>
    );
}
