import React from 'react';
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import Router from './Router';
import './App.css';
import {ThemeProvider} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Loader from "./components/Common/Loader";

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '100%',
        margin: 'auto'
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        maxHeight: '100vh',
        minHeight: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 30px',
        boxSizing: 'border-box'
    },
    menu: {
        borderRight: '1px solid #707070',
        padding: '30px 0!important'
    },
    fullHeight: {
        height: '100vh',
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#006cb1cc',
            main: '#006CB1',
            dark: '#024875',
            contrastText: '#006CB1',
        },
        secondary: {
            light: '#eff3f5',
            main: '#E4F1F9',
            dark: '#76bce7',
            contrastText: '#006CB1',
        },
        text: {
            primary: "#006CB1",
            secondary: "#B2CEDF"
        },
        error: {
            main: "#ff604f",
            secondary: "#F9E4E4"
        },
        disabled: {
            main: "gray",
            secondary: "gray"
        }
    },
    typography: {
        fontSize: 30,
        h1: {
            fontSize: 50
        },
        h3: {
            fontSize: 40
        },
        h4: {
            fontSize: 30
        },
        paragraph: {
            fontSize: 30,
            lineHeight: 48,
            marginBottom: 30
        },
        button: {
            textTransform: 'none',
            fontSize: 40,
            lineHeight: '46px',
            fontWeight: "normal",
            letterSpacing: 0,
            justifyContent: "space-between",
            height: '115px',
        }
    },
    overrides: {
        MuiInputLabel: {
            outlined: {
                '&$shrink': {
                    transform: 'translate(14px, -24px) scale(0.75)',
                    background: '#fff'
                },
            },
        },
        MuiPickersModalDialogRoot:{
            fontSize:"20px"
        },
        MuiOutlinedInput: {
            root: {
                height: 90,
                fontSize: 40
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 40,
                lineHeight: 1.8
            }
        },
        MuiSelect: {
            icon: {
                top: "calc(50% - 27px)",
            }
        }
    }
});

function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Grid container spacing={1} className={classes.root}>
                <main className={classes.content}>
                    <Loader />
                    <Router/>
                </main>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
