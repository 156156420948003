import {FILES, QUESTIONS, TYPE_FILES} from "../actions/action-types";

export const areaCustomerReducer = (state = [], action) => {
    if (action.type === QUESTIONS) {
        return action.data;
    }
    if (action.type === TYPE_FILES) {
        return action.data;
    }
    if (action.type === FILES) {
        return action.data;
    }

    return state;
};
