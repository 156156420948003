export function parseArrayForCarousel(toParse,numbers) {
    if(!toParse){
        return [];
    }
    let index = 0;
    let result = [];
    while(index < toParse.length){
        result.push(toParse.slice(index, index+numbers));
        
        index+=numbers;
    }
    return result;
}

export function checkImageUrl(url) {
    fetch(url)
        .then((res) => {
            return res.status !== 404;
        })
        .catch(() => {
            return false;
        })
}