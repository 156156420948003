import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { DialogTitle, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '55px!important',
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        marginTop: '100px',
        padding: '6px',
        color: theme.palette.text.primary,
    },
    text: {
        fontSize: '20px',
    },
    button: {
        padding: '6px 50px',
        margin: 'auto',
    },
    textRed: {
        color: '#BE2020',
        fontSize: '30px',
    },
    titleDialog: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        marginBottom: '30px',
    },
    subTitleDialog: {
        color: theme.palette.text.secondary,
        fontSize: '30px',
    },
    DialogContent: {
        textAlign: 'center',
        padding: '0 106px',
    },
    DialogActions: {
        padding: '30px 0',
    },
}));

export default function DialogAppointment() {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg">
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#F8917B',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.DialogContent}>
                    <Typography className={classes.titleDialog}>{"Vous n'avez aucun rendez-vous aujourd'hui."}</Typography>
                    <Typography className={classes.subTitleDialog}>
                        Vous pouvez néanmoins en prendre un, demander une consultation sans rendez-vous ou accéder à votre espace personnel en cliquant sur suivant.
                    </Typography>
                    <Typography className={classes.textRed}>Enlevez votre carte du lecteur si vous souhaitez être déconnecté.</Typography>
                </DialogContent>
                <DialogActions className={classes.DialogActions}>
                    <Button variant="contained" className={classes.button} color="secondary" onClick={handleClose}>
                        Suivant
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
