import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setWaitingRoom} from "../../utils/fetcher";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {setCurrentAppointment, setType} from "../../actions";
import {createHashHistory} from 'history';
import Footer from "../Common/Footer";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 36px',
        marginTop: "30px"
    },
    icon: {
        fontSize: '55px!important'
    },
    iconCheckbox: {
        fontSize: 40
    },
    iconCheckboxChecked: {
        fontSize: 40,
        color: theme.palette.primary.main
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    alignCenter: {
        textAlign: 'center'
    },
    wrapListAppointment: {
        borderTop: "1px solid transparent",
        borderColor: theme.palette.text.secondary,
        paddingTop: "28px",
        marginTop: "40px"
    },
    wrapListAppointmentDisabled: {
        borderTop: "1px solid transparent",
        borderColor: theme.palette.disabled,
        paddingTop: "28px",
        marginTop: "40px"
    },
    disabled: {
        color: "gray"
    }
}));

export default function ListAppointment(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const patient = useSelector(state => state.currentPatient);
    const history = createHashHistory();

    const valid = appointment => {
        dispatch(setCurrentAppointment(appointment));
        if (appointment.is_payable === "ok") {
            history.push('/valider-consultation');
        } else {
            if (appointment.use_mikajaki === 'Y') {
                history.push('/info-rendez-vous');
            } else {
                dispatch(setWaitingRoom(appointment));
            }
        }
    }

    const setTypeFunc = val => {
        dispatch(setType(val));
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Vos rendez-vous</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Choisissez votre rendez-vous</Typography>
            </div>
            <div className="wrap-content-page">
                <div className={classes.alignCenter}>
                    {props.appointments.map((item, key) =>
                        (
                            <div className={classes.wrapListAppointment} key={`item-${key}`}>
                                <Typography key={key} variant="h4" component="h4" color={!item.isTreated ? "primary" : ""} className={item.isTreated ? classes.disabled : ""}>
                                    {patient.nom_civilite} {patient.nom_patient} {patient.prenom_patient} : Vous avez un rendez-vous aujourd'hui à {item.heure_debut_rdv} avec {item.toubib}
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={() => valid(item)}
                                    disabled={item.isTreated}
                                >
                                    Choisir ce rendez-vous
                                </Button>
                            </div>
                        )
                    )}
                </div>
            </div>
            <Footer banner={false}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={()=>setTypeFunc('makeAppointment')}
                    href="#/motifs"
                >
                    Prendre rendez-vous
                </Button>
            </Footer>
        </>
    )
}
