import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/Common/Footer';
import { setCurrentReason } from '../actions';
import { createHashHistory } from 'history';
import Carousel from 'react-material-ui-carousel';
import { Card, CardActionArea, CardContent, Grid } from '@material-ui/core';
import { parseArrayForCarousel } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    wrapBtn: {
        textAlign: 'center',
    },
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    fullHeight: {
        flex: 1,
        paddingTop: '30px',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
    },
    select: {
        width: '783px',
    },
    input: {
        width: '783px',
        marginTop: '30px',
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
        width: 'fit-content',
    },
    alert: {
        fontSize: '20px',
    },
    grid: {
        justifyContent: 'center',
    },
    cardContent: {
        padding: "25px",
    }
}));

export default function Reasons() {
    const classes = useStyles();
    console.log(classes)
    const listReasons = useSelector((state) => (state.reasons ? state.reasons : []));
    const dispatch = useDispatch();
    const history = createHashHistory();

    const send = (reasonSelected) => {
        dispatch(setCurrentReason(reasonSelected));
        history.push('/praticiens');
    };

    let list = parseArrayForCarousel(listReasons, 6);
    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    Prendre mon rendez-vous
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Choisissez un motif de consultation
                </Typography>
            </div>
            <div className="wrap-content-page">
                <div className="carouselWrap">
                    {list && list.length > 0 && (
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            indicators={false}
                            autoPlay={false}
                            className="caroussel"
                            navButtonsProps={{ className: 'CarouselButtons', disabled: list.length <= 1 }}
                            navButtonsWrapperProps={{ className: 'CarouselButtonsWrapper' }}
                        >
                            {list.map((reasonList, index) => (
                                <Grid container className={classes.grid} spacing={2} key={'reason-' + index}>
                                    {reasonList.map((reasonItem, reasonIndex) => (
                                        <Card className={classes.card} key={reasonIndex}>
                                            <CardActionArea>
                                                <CardContent className={classes.cardContent} onClick={() => send(reasonItem)}>
                                                    <div className={classes.alignLeft}>
                                                        <Typography variant="body2" component="p" className={classes.text}>
                                                            {reasonItem.libelle}
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </Grid>
                            ))}
                        </Carousel>
                    )}
                </div>
                <div className={classes.wrapBtn}>
                    <Footer banner={false} />
                </div>
            </div>
        </>
    );
}
