import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function Loading() {

    return (
        <div className="text-center">
            <LinearProgress />
            <LinearProgress color="secondary" />
        </div>
    )
}
