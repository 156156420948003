import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {saveAppointment} from "../utils/fetcher";
import Alert from "@material-ui/lab/Alert";
import defaultImg from '../assets/images/default.png';
import DialogMikajaki from "../components/Common/DialogMikajaki";
import DialogConfirmMikajaki from "../components/Common/DialogConfirmMikajaki";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: '50px',
        padding: '6px 62px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    wrapBloc: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between"
    },
    wrapBtns: {
        display: 'flex',
        justifyContent: "space-between",
        marginTop: '40px'
    },
    btn: {
        fontSize: '40px',
        padding: '6px',
        height: 'auto'
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px',
        color: theme.palette.text.primary
    },
    text: {
        marginBottom: '30px'
    },
    alert: {
        fontSize: "20px",
        marginTop: "20px",
        alignItems: "center"
    },
}));

export default function PreAppointmentDetail(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const availability = useSelector(state => state.availabilities ? state.availabilities[0] : []);
    const [isValidMikajaki, setIsValidMikajaki] = useState(false);
    const [isShowDialogMikajaki, setIsShowDialogMikajaki] = useState(false);

    const save = () => {
        dispatch(saveAppointment(props.appointment));
    }

    const callMikajakiFunc = () => {
        setIsShowDialogMikajaki(true);
    }

    const validMikajaki = () => {
        save();
        setIsValidMikajaki(true);
    }

    return (
        <>
            {isShowDialogMikajaki && <DialogConfirmMikajaki isValidMikajaki={validMikajaki} appointment={props.appointment} />}
            {isValidMikajaki && <DialogMikajaki appointment={props.appointment} />}

            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Votre rendez-vous</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Merci de nous avoir prévenus de votre arrivée, voici un récapitulatif</Typography>
            </div>
            <div className="wrap-content-page">
                {props.appointment &&
                    <>
                        <div className={classes.wrapBloc}>
                            <div>
                                <Typography className={classes.text} variant="h3" color="error">Temps d'attente estimé
                                    : {props.appointment.tps_attente}</Typography>
                                <Typography variant="h1" component="h1" color="primary">
                                    Rendez-vous avec {availability.toubib} à {availability.heure_debut}
                                </Typography>
                            </div>
                            <div>
                                <img src={defaultImg} alt="defaut"/>
                            </div>
                        </div>
                        <div className={classes.wrapBtns}>
                            <Button
                                variant="contained"
                                color="error"
                                className={classes.buttonRed}
                                href="/"
                            >
                                Annuler
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.btn}
                                onClick={(props.appointment.use_mikajaki && props.appointment.use_mikajaki === 'Y') ? callMikajakiFunc : save}
                            >
                                Continuer
                            </Button>
                        </div>
                    </>
                }
                {props.message && props.message.code === 'noAvailabilities' && <Alert className={classes.alert} severity="error">{props.message.text}</Alert>}
            </div>
            {!props.appointment && <Footer banner={false} />}
        </>
    )
}
