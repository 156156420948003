import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Grid from '@material-ui/core/Grid';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { createHashHistory } from 'history';
import { setDoctor } from '../actions';
import Footer from '../components/Common/Footer';
import { Alert } from '@material-ui/lab';
import { checkImageUrl, parseArrayForCarousel } from '../utils/utils';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    img: {
        fontSize: '153px!important',
        color: 'white',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    marginAuto: {
        margin: 'auto',
    },
    wrapCards: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    alignCenter: {
        textAlign: 'center',
    },
    button: {
        padding: '6px 62px',
    },
    caroussel: {
        maxWidth: '1147px',
        margin: 'auto',
        width: '100%',
        display: 'flex',
    },
    white: {
        color: 'white',
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    px0: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    iconCheckbox: {
        fontSize: 40,
    },
    iconCheckboxChecked: {
        fontSize: 40,
        color: theme.palette.primary.main,
    },
    wrapBtn: {
        justifyContent: 'flex-end',
        padding: 0,
    },
    banner: {
        boxShadow: 'none',
    },
    cardMedia: {
        paddingTop: '10px',
        justifyContent: 'center',
        width: '100%',
    },
    bloc: {
        height: '300px',
        overflow: 'hidden',
    },
    accountIcon: {
        width: '100%',
        height: 'auto',
        maxHeight: '150px',
        color: 'white',
    },
}));

export default function ListDoctors() {
    const history = createHashHistory();
    const classes = useStyles();
    const listDoctors = useSelector((state) => (state.doctors.all ? state.doctors.all : []));
    const dispatch = useDispatch();
    const message = useSelector((state) => state.messageWithCode);

    const toggle = (item) => {
        dispatch(setDoctor(item));
        history.push('/disponibilites');
    };

    const anyDoctor = () => {
        toggle({});
    };

    const giveMediaCard = (img) => {
        if (img && checkImageUrl(img)) {
            return <CardMedia component={'img'} className={classes.cardMedia} image={img} />;
        } else {
            return (
                <CardMedia className={classes.cardMedia}>
                    <AccountCircleIcon className={classes.accountIcon} />
                </CardMedia>
            );
        }
    };

    let list = parseArrayForCarousel(listDoctors, 6);

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    Prendre mon rendez-vous
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Choisissez votre praticien
                </Typography>
            </div>
            <div className="wrap-content-page">
                <div className="carouselWrap">
                    {list.length > 0 && (
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            indicators={false}
                            autoPlay={false}
                            className="caroussel"
                            navButtonsProps={{ className: 'CarouselButtons', disabled: list.length <= 1 }}
                            navButtonsWrapperProps={{ className: 'CarouselButtonsWrapper' }}
                        >
                            {list.map((elet, index) => (
                                <Card raised className={classes.banner} key={`item-${index}`}>
                                    <Grid container spacing={0} className={classes.BannerGrid}>
                                        {elet.map((item, key) => (
                                            <Grid item key={`sub-${key}`}>
                                                <Card className={classes.card} onClick={() => toggle(item)}>
                                                    <CardActionArea>
                                                        {giveMediaCard(item.photo_praticien)}
                                                        <CardContent className={classes.px0}>
                                                            <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                                                {item.toubib}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Card>
                            ))}
                        </Carousel>
                    )}
                    {message && message.code === 'noAvailabilities' && (
                        <Alert className={classes.alert} severity="error">
                            {message.text}
                        </Alert>
                    )}
                </div>
            </div>
            <Footer banner={false}>
                <Button type="submit" title="Submit" variant="contained" color="secondary" className={classes.button} onClick={anyDoctor}>
                    {"Je n'ai pas de préférence"}
                </Button>
            </Footer>
        </>
    );
}
