import { useState, useRef, useEffect } from "react";

function useVisible(initialIsVisible, listInputs) {
    const [isVisible, setIsVisible] = useState(initialIsVisible);
    const ref = useRef(null);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target) && !listInputs.includes(event.target.name)) {
                setIsVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [listInputs]);

    return { ref, isVisible, setIsVisible };
}

export default useVisible;
