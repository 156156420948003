import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setWaitingRoom} from "../../utils/fetcher";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import DialogConfirmMikajaki from "../Common/DialogConfirmMikajaki";
import DialogMikajaki from "../Common/DialogMikajaki";
import Footer from "../Common/Footer";
import {createHashHistory} from 'history';
import {setCurrentAppointment} from "../../actions";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    alignCenter: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: 600
    },
    infosPatient: {
        color: "#FA9178",
        fontWeight: "bold",
        marginBottom: "20px"
    },
    infos: {
        marginBottom: "40px"
    },
    directives: {
        marginBottom: "20px"
    }
}));

export default function OneAppointment() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isShowDialogMikajaki, setIsShowDialogMikajaki] = useState(false);
    const [isValidMikajaki, setIsValidMikajaki] = useState(false);
    const patient = useSelector(state => state.currentPatient);
    const appointment = useSelector(state => state.currentAppointment);
    const history = createHashHistory();

    const valid = () => {
        dispatch(setCurrentAppointment(appointment));
        if (appointment.is_payable === "ok") {
            history.push('/valider-consultation');
        } else {
            if (appointment.use_mikajaki === 'Y') {
                setIsShowDialogMikajaki(true);
            } else {
                dispatch(setWaitingRoom(appointment));
            }
        }
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Votre rendez-vous</Typography>
            </div>
            <div className="wrap-content-page">
                <div className={classes.alignCenter}>

                    <Typography variant="h3" component="h3" color="primary" className={classes.infosPatient}>
                        {patient.nom_civilite} {patient.nom_patient} {patient.prenom_patient}
                    </Typography>
                    <Typography variant="h3" component="h3" color="primary" className={classes.infos}>
                         Vous avez un rendez-vous aujourd'hui à {appointment.heure_debut_rdv} avec {appointment.toubib}
                    </Typography>
                    {appointment.use_mikajaki === 'Y' &&
                        <>
                            <Typography variant="h3" component="h3" color="primary" className={classes.directives}>
                                Afin de procéder à votre rendez-vous, nous allons faire une première analyse de votre oeil.
                            </Typography>
                            <Typography variant="h3" component="h3" color="primary" className={classes.bold}>
                                Merci de rester immobile face à la borne et de rester jusqu'à la fin du processus.
                            </Typography>
                        </>
                    }
                </div>
            </div>
            <Footer banner={false} >
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={valid}
                >
                    Valider
                </Button>
            </Footer>
            {isShowDialogMikajaki && <DialogConfirmMikajaki isValidMikajaki={setIsValidMikajaki} appointment={appointment} />}
            {isValidMikajaki && <DialogMikajaki appointment={appointment} />}
        </>
    )
}
