import React, {useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {closeMikajaki} from "../../utils/fetcher";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    white: {
        color: "white"
    }
}));

export default function DialogMikajaki(props) {
    const [open, setOpen] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const tokenMikajaki = useSelector(state => state.tokenMikajaki);

    window.addEventListener("message", e => {
        if (e.data.eventFromMikajaki) {
            console.log('yessss!!!!!!', e.data.eventFromMikajaki);
            dispatch(closeMikajaki(props.appointment));
            setOpen(false);
        }
    }, false);

    return (
        <Dialog aria-labelledby="simple-dialog-title" open={open} fullScreen>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="close">
                        <CloseIcon className={classes.white} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <iframe
                id="inlineFrameMikajaki"
                title="Mikajaki"
                width="100%"
                height="100%"
                src={`http://localhost:5003/eyelib-manager#/registration/${tokenMikajaki}`}>
            </iframe>
        </Dialog>
    )
}
