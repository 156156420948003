import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardMedia from "@material-ui/core/CardMedia";
import DescriptionIcon from "@material-ui/icons/Description";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {printPdf} from "../../utils/fetcher";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: "849px",
        width: "849px"
    },
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
    },
    cardMedia: {
        background: "white",
        borderRadius: "50%",
        width: "153px",
        height: "153px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto"
    },
    img: {
        fontSize: '76px!important'
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px 50px',
        color: theme.palette.text.primary,
    },
    button: {
        padding: '6px 50px',
    },
    icon: {
        fontSize: '55px!important'
    },
    text: {
        fontSize: '29px',
        fontWeight: 'bold'
    },
    description: {
        fontSize: "40px",
    },
    card: {
        textAlign: 'center',
        marginRight: '20px',
        padding: "23px",
        background: theme.palette.secondary.main,
        width: "312px",
        boxSizing: "border-box"
    },
    dialogContent: {
        overflow: "initial"
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px"
    }
}));


export default function DetailFile(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        props.onClose(false);
    };

    const print = () => {
        dispatch(printPdf(props.file.file, props.file.id_file_rdv));
    }

    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby="draggable-dialog-title"
                maxWidth={false}
            >
                <div style={{width: 849}}>
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.details}>
                            <Card className={classes.card}>

                                <CardMedia className={classes.cardMedia}>
                                    <DescriptionIcon className={classes.img} color="secondary" />
                                </CardMedia>
                                <CardContent className={classes.p0}>
                                    <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                        {props.file.nom_document_file_rdv}
                                    </Typography>
                                </CardContent>

                            </Card>
                            <div className={classes.description}>
                                <Typography gutterBottom variant="body2" component="p">
                                    Date de dépot: {props.file.dateenreg}
                                </Typography>
                                {props.file.type_file_rdv &&
                                <Typography gutterBottom variant="body2" component="p">
                                    Type: {props.file.type_file_rdv}
                                </Typography>
                                }
                                {props.file.last_print_file &&
                                <Typography gutterBottom variant="body2" component="p">
                                    Consulté le: {props.file.last_print_file}
                                </Typography>
                                }
                                {props.file.nbr_impression_file_rdv &&
                                <Typography gutterBottom variant="body2" component="p">
                                    Impressions: {props.file.nbr_impression_file_rdv}
                                </Typography>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="secondary"
                            onClick={print}
                        >
                            Imprimer
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            className={classes.buttonRed}
                            onClick={handleClose}
                        >
                            Fermer
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
