import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { editPatient, getPatient } from '../utils/fetcher';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import useVisible from '../useVisible';
import useMask from 'react-mask-hook';
import DateFnsUtils from '@date-io/date-fns';
import { MenuItem } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DialogRefresh from '../components/Common/DialogRefresh';
import Footer from '../components/Common/Footer';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    alignCenter: {
        textAlign: 'center',
    },
    cookieAlert: {
        '& .MuiAlert-icon': {
            fontSize: 40,
        },
    },
    datepickerCustom: {
        fontSize: '20px',
    },
    wrapKeyBoard: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99,
    },
    select: {
        width: '100%',
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px 36px',
        color: theme.palette.text.primary,
        marginLeft: '20px',
    },
    alert: {
        fontSize: '20px',
        marginBottom: '20px',
        alignItems: 'center',
    },
}));

export default function InfosPatient() {
    const classes = useStyles();
    const [layoutName, setLayoutName] = useState('default');
    const listInputs = [
        'adresse_patient',
        'nom_patient',
        'prenom_patient',
        'tel_fixe_patient',
        'datenaissance',
        'email_patient',
        'tel_mobile_patient',
        'code_postal_patient',
        'ville_patient',
        'nom_naissance_patient',
        'id_civilite'
    ];
    const { ref, isVisible, setIsVisible } = useVisible(false, listInputs);
    const [top, setTop] = useState(0);
    const patient = useSelector((state) => (state.currentPatient ? state.currentPatient : {}));
    const isAuthenticate = useSelector((state) => state.isAuthenticate);

    const layoutDefaultType = {
        default: ['a z e r t y u i o p', 'q s d f g h j k l m', '{shift} w x c v b n {bksp}', '- , {space} . {ok}'],
        shift: ['A Z E R T Y U I O P', 'Q S D F G H J K L M', '{shift} W X C V B N {bksp}', '- , {space} . {ok}'],
    };
    const layoutNumeric = {
        default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {ok}'],
    };
    const layoutEmail = {
        default: ['1 2 3 4 5 6 7 8 9 0 =', 'a z e r t y u i o p', 'q s d f g h j k l m', '{shift} w x c v b n {bksp}', '@ - _ , . {ok}'],
        shift: ['! ? # % & * ( ) +', 'A Z E R T Y U I O P', 'Q S D F G H J K L M', '{shift} W X C V B N {bksp}', '@ - _ , . {ok}'],
    };

    const [inputs, setInputs] = useState(patient && isAuthenticate ? { ...patient } : {});
    const [inputName, setInputName] = useState('default');
    const [validDate, setValidDate] = useState(3);
    const [layoutTye, setLayoutTye] = useState(layoutDefaultType);
    const type = useSelector((state) => state.type);
    const { register, handleSubmit, errors, control } = useForm();
    const message = useSelector((state) => state.messageWithCode);
    const dispatch = useDispatch();

    const dateUtils = new DateFnsUtils();
    const today = new Date();

    const keyboard = useRef();
    const nameRef = useRef();
    const firstNameRef = useRef();
    const phoneRef = useRef();
    const bodRef = useRef();
    const emailRef = useRef();
    const mobileRef = useRef();
    const nameBirthRef = useRef();
    const zipCodeRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();

    const setPatientFunc = () => {
        if (validDate === 1 || (validDate === 3 && patient)) {
            const date = inputs['datenaissance'].includes('/') ? dateUtils.parse(inputs['datenaissance'], 'dd/MM/yyyy') : dateUtils.parse(inputs['datenaissance'], 'ddMMyyyy');
            let inputsCopy = {...inputs}
            inputsCopy['datenaissance'] = dateUtils.format(date, 'yyyy-MM-dd');
            if (Object.keys(patient).length === 0 && !isAuthenticate) {
                dispatch(getPatient(inputsCopy));
            } else {
                dispatch(editPatient(inputsCopy, patient.guid_patient, patient.rdv_today === 1));
            }
        }
    };

    const onKeyPress = (button) => {
        if (button === '{shift}' || button === '{lock}') handleShift();
        if (button === '{ok}') {
            setIsVisible(false);
        }
    };

    const getInputValue = (inputName) => {
        return inputs[inputName] || '';
    };

    const onChangeAll = (inputsKeyboard) => {
        if (inputsKeyboard.datenaissance) {
            if (
                !dateUtils.isValid(dateUtils.parse(inputsKeyboard.datenaissance, 'ddMMyyyy')) ||
                !dateUtils.isAfter(dateUtils.parse(inputsKeyboard.datenaissance, 'ddMMyyyy'), new Date(today.getFullYear() - 150, 1, 1))
            ) {
                setValidDate(0);
            } else {
                setValidDate(1);
            }
        }
        if (inputsKeyboard.datenaissance && inputsKeyboard.datenaissance.length > 8) {
            inputsKeyboard.datenaissance = getInputValue('datenaissance');
        }
        inputs[inputName] = inputsKeyboard[inputName];
        setInputs({ ...inputs });
    };

    const handleShift = () => {
        setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    };

    const onChangeInput = (event) => {
        const inputVal = event.target ? event.target.value : "";
        setInputs({
            ...inputs,
            [inputName]: inputVal,
        });
        keyboard.current.setInput(inputVal);

    };

    const onChangeInputDate = (value) => {
        setInputs({
            ...inputs,
            [inputName]: value,
        });
        keyboard.current.setInput(value);
    };

    const onFocus = (name, refInput, type = layoutDefaultType) => {
        setInputName(name);
        if(Object.keys(type).length !== 0){
            setLayoutTye(type);
            setIsVisible(true);
        }
        setTop(refInput.current.getBoundingClientRect().top - nameRef.current.getBoundingClientRect().top + 115);
    };

    const scrollToRef = (refInput) => {
        refInput.current.scrollIntoView();
    };

    const dobMask = useMask({
        value: getInputValue('datenaissance'),
        onChange: onChangeInputDate,
        mask: '## / ## / ####',
        placeholder: 'JJ / MM / AAAA',
    });

    const phoneFixMask = useMask({
        value: getInputValue('tel_fixe_patient'),
        onChange: onChangeInput,
        mask: '##########',
        placeholder:'',
    });

    const phoneMobileMask = useMask({
        value:getInputValue('tel_mobile_patient'),
        onChange: onChangeInput,
        mask: '##########',
        placeholder:'',
    });

    const cpMask = useMask({
        value:getInputValue('code_postal_patient'),
        onChange: onChangeInput,
        mask: '#####',
        placeholder:'',
    });
    return (
        <>
            {type === 'quitBorne' && <DialogRefresh text={message.text} subText="Vous allez être redirigé vers la page d'accueil" />}
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    {patient ? 'Voulez-vous modifier vos informations ?' : "Vous n'avez pas de carte vitale"}
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    {patient
                        ? 'Vous pouvez les modifier ou cliquer directement sur confirmer'
                        : 'Renseignez ce formulaire pour que nous puissions vous retrouver/ajouter'}
                </Typography>
            </div>
            <div className="wrap-content-page" style={{ position: 'relative' }}>
                {message && message.code === 'patient' && (
                    <Alert severity="error" className={classes.alert}>
                        {message.text}
                    </Alert>
                )}
                <form onSubmit={handleSubmit(setPatientFunc)}>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <Controller 
                                control={control}
                                name="id_civilite"
                                defaultValue={getInputValue('id_civilite')}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        select
                                        label="Civilité"
                                        fullWidth
                                        {...field}
                                        onFocus={() => setInputName('id_civilite')}
                                        onChange={onChangeInput}
                                        
                                        value={getInputValue('id_civilite')}
                                        defaultValue={getInputValue('id_civilite')}
                                    >
                                        <MenuItem value="2" key="civ_2">
                                            Mme
                                        </MenuItem>
                                        <MenuItem value="1" key="civ_1">
                                            M.
                                        </MenuItem>
                                        <MenuItem value="10" key="civ_10">
                                            Enfant
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                            
                            
                                
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Nom *"
                                value={getInputValue('nom_patient')}
                                onFocus={() => onFocus('nom_patient', nameRef)}
                                onChange={onChangeInput}
                                onClick={() => scrollToRef(nameRef)}
                                variant="outlined"
                                type="text"
                                name="nom_patient"
                                inputRef={register({
                                    required: true,
                                })}
                                fullWidth
                                ref={nameRef}
                            />
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {errors.nom_patient && 'Ce champ est obligatoire'}
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Nom de naissance"
                                value={getInputValue('nom_naissance_patient')}
                                onFocus={() => onFocus('nom_naissance_patient', nameBirthRef)}
                                onChange={onChangeInput}
                                variant="outlined"
                                type="text"
                                name="nom_naissance_patient"
                                fullWidth
                                ref={nameBirthRef}
                                onClick={() => scrollToRef(nameBirthRef)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Prénom *"
                                value={getInputValue('prenom_patient')}
                                onFocus={() => onFocus('prenom_patient', firstNameRef)}
                                onChange={onChangeInput}
                                variant="outlined"
                                onClick={() => scrollToRef(firstNameRef)}
                                type="text"
                                name="prenom_patient"
                                inputRef={register({
                                    required: true,
                                })}
                                fullWidth
                                autoComplete="no"
                                ref={firstNameRef}
                            />
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {errors.prenom_patient && 'Ce champ est obligatoire'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Date de naissance *"
                                variant="outlined"
                                type="text"
                                name="datenaissance"
                                fullWidth
                                onClick={() => scrollToRef(bodRef)}
                                autoComplete="no"
                                {...dobMask}
                                onFocus={() => onFocus('datenaissance', bodRef, layoutNumeric)}
                                inputProps={{ maxLength: 8 }}
                                inputRef={register({
                                    required: true,
                                })}
                                ref={bodRef}
                            />
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {validDate === 0 && 'Date de naissance non validée'}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {errors.datenaissance && 'Ce champ obligatoire'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...phoneFixMask}
                                onFocus={() => onFocus('tel_fixe_patient', phoneRef, layoutNumeric)}
                                onClick={() => scrollToRef(phoneRef)}
                                label="Fixe"
                                variant="outlined"
                                type="text"
                                name="tel_fixe_patient"
                                fullWidth
                                autoComplete="no"
                                inputRef={register()}
                                ref={phoneRef}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...phoneMobileMask}
                                onFocus={() => onFocus('tel_mobile_patient', mobileRef, layoutNumeric)}
                                label="Mobile"
                                variant="outlined"
                                type="text"
                                name="tel_mobile_patient"
                                fullWidth
                                onClick={() => scrollToRef(mobileRef)}
                                autoComplete="no"
                                inputRef={register()}
                                ref={mobileRef}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="E-mail *"
                                value={getInputValue('email_patient')}
                                onFocus={() => onFocus('email_patient', emailRef, layoutEmail)}
                                onChange={onChangeInput}
                                variant="outlined"
                                type="text"
                                name="email_patient"
                                inputRef={register({
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Adresse email non validée',
                                    },
                                    required: true,
                                })}
                                fullWidth
                                onClick={() => scrollToRef(emailRef)}
                                autoComplete="no"
                                ref={emailRef}
                            />
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {errors.email_patient && errors.email_patient.message}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom color="error">
                                {errors.email_patient && 'Ce champ obligatoire'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Adresse"
                                value={getInputValue('adresse_patient')}
                                onFocus={() => onFocus('adresse_patient', addressRef)}
                                onChange={onChangeInput}
                                variant="outlined"
                                type="text"
                                name="adresse_patient"
                                fullWidth
                                autoComplete="no"
                                ref={addressRef}
                                onClick={() => scrollToRef(addressRef)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                {...cpMask}
                                label="Code postale"
                                onFocus={() => onFocus('code_postal_patient', zipCodeRef, layoutNumeric)}
                                onChange={onChangeInput}
                                variant="outlined"
                                type="text"
                                name="code_postal_patient"
                                fullWidth
                                autoComplete="no"
                                ref={zipCodeRef}
                                onClick={() => scrollToRef(zipCodeRef)}
                                
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Ville"
                                value={getInputValue('ville_patient')}
                                onFocus={() => onFocus('ville_patient', cityRef)}
                                onChange={onChangeInput}
                                variant="outlined"
                                type="text"
                                name="ville_patient"
                                fullWidth
                                autoComplete="no"
                                ref={cityRef}
                                onClick={() => scrollToRef(cityRef)}
                                
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.alignCenter}>
                            <div className={classes.wrapKeyBoard} style={!isVisible ? { display: 'none' } : { display: 'block', top: top }} ref={ref}>
                                <Keyboard
                                    keyboardRef={(r) => (keyboard.current = r)}
                                    onChangeAll={onChangeAll}
                                    layoutName={layoutName}
                                    onKeyPress={onKeyPress}
                                    inputName={inputName}
                                    layout={layoutTye}
                                    theme={'hg-theme-default myTheme1'}
                                    disableCaretPositioning={true}
                                    display={{
                                        '{shift}': '⇧',
                                        '{bksp}': '⌫',
                                        '{alt}': '.?123',
                                        '{smileys}': '\uD83D\uDE03',
                                        '{shiftactivated}': '⇧',
                                        '{enter}': 'return',
                                        '{altright}': '.?123',
                                        '{downkeyboard}': '🞃',
                                        '{space}': '⌴',
                                        '{default}': 'ABC',
                                        '{back}': '⇦',
                                        '{ok}': 'ok',
                                    }}
                                    preventMouseDownDefault={true}
                                />
                            </div>
                            <Footer banner={false}>
                                <Button
                                    type="submit"
                                    title="Submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                >
                                    Valider
                                </Button>
                            </Footer>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}
