import {combineReducers} from 'redux'
import {settingsReducer} from "./settings";
import {RESET_APP} from "../actions/action-types";
import {patientsReducer} from "./patients";
import {typeReducer} from "./type";
import {currentPatientReducer} from "./currentPatient";
import {appointmentsReducer} from "./appointments";
import {currentAppointmentReducer} from "./currentAppointment";
import {messageWithCodeReducer} from "./mesageWithCode";
import {authenticateReducer} from "./authenticate";
import {areaCustomerReducer} from "./areaCustomer";
import {professionsReducer} from "./professions";
import {doctorReducer} from "./doctor";
import {doctorsReducer} from "./doctors";
import {availabilitiesReducer} from "./availabilities";
import {dataPrintReducer} from "./dataPrint";
import {reasonsReducer} from "./reasons";
import {locationsReducer} from "./locations";
import {currentReasonReducer} from "./currentReason";
import {currentLocationReducer} from "./currentLocation";
import { categoriesReducer } from './categories';
import { loadingReducer } from './loading';

const appReducer = combineReducers({
    settings: settingsReducer,
    patients: patientsReducer,
    currentPatient: currentPatientReducer,
    type: typeReducer,
    appointments: appointmentsReducer,
    currentAppointment: currentAppointmentReducer,
    messageWithCode: messageWithCodeReducer,
    isAuthenticate: authenticateReducer,
    questions: areaCustomerReducer,
    files: areaCustomerReducer,
    fileTypes: areaCustomerReducer,
    professions: professionsReducer,
    doctors: doctorsReducer,
    doctor: doctorReducer,
    availabilities: availabilitiesReducer,
    dataPrint: dataPrintReducer,
    reasons: reasonsReducer,
    locations: locationsReducer,
    currentReason: currentReasonReducer,
    currentLocation: currentLocationReducer,
    categories: categoriesReducer,
    loading: loadingReducer
});

export const rootReducer = (state, action) => {
    if (action.type === RESET_APP) {
        // only keep locale
        state = {};
    }

    return appReducer(state, action);
};
