import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useDispatch} from "react-redux";
import {openMikajaki} from "../../utils/fetcher";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '55px!important'
    },
    text: {
        fontSize: '20px',
        color: theme.palette.text.secondary,
    },
    button: {
        padding: '6px 50px',
        margin: 'auto'
    },
    textRed: {
        color: 'red'
    },
    titleDialog: {
        color: theme.palette.text.primary
    },
    subTitleDialog: {
        color: theme.palette.text.secondary
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        margin: '30px 0'
    },
    DialogContent: {
        padding: "67px 47px 37px 47px"
    }
}));

export default function DialogConfirmMikajaki(props) {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const valid = () => {
        dispatch(openMikajaki(props.appointment))
        props.isValidMikajaki();
        setOpen(false);
        // Rediriger l'utilisateur vers une nouvelle route
        history.push('/menu');
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent className={classes.DialogContent}>
                    <DialogContentText id="alert-dialog-description" className={classes.text}>
                        <Typography variant="p" component="p">
                            Les informations recuilles sur ce formaulaire .....
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie aliquet libero at semper. Nulla facilisi. Nam non consectetur orci. Duis ante nulla, facilisis vel viverra vitae, venenatis ullamcorper turpis. Praesent tempor augue in sollicitudin placerat. Duis iaculis erat in mauris facilisis porttitor. Integer in elit in purus volutpat tempus. Sed pretium venenatis posuere. Nam laoreet tempus consequat. Pellentesque volutpat condimentum sapien ac feugiat. Suspendisse mattis ligula consequat lacus malesuada posuere. Nullam malesuada nec leo quis porttitor.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        onClick={valid}
                    >
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

