import React, {useRef, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import {sendQuestion} from "../utils/fetcher";
import useVisible from "../useVisible";
import Keyboard from "react-simple-keyboard";
import DialogRefresh from "../components/Common/DialogRefresh";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    wrapBtn: {
        textAlign: "center"
    },
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    fullHeight: {
        flex: 1,
        paddingTop: '30px',
        flexDirection: "column",
        display: "flex",
        alignItems: "center"
    },
    select: {
        width: "783px"
    },
    link: {
        fontSize: "40px",
        textDecoration: "underline",
        marginTop: "40px",
        fontWeight: "300"
    },
    input: {
        width: "783px",
        marginTop: "30px"
    },
    alert: {
        fontSize: "20px"
    }
}));

export default function Question() {
    const layoutDefaultType = {
        default: [
            "a z e r t y u i o p",
            "q s d f g h j k l m",
            "{shift} w x c v b n {bksp}",
            "- , {space} . {ok}"
        ],
        shift: [
            "A Z E R T Y U I O P",
            "Q S D F G H J K L M",
            "{shift} W X C V B N {bksp}",
            "- , {space} . {ok}"
        ]
    };
    const classes = useStyles();
    const [questionId, setQuestionId] = useState("");
    const [expanded, setExpanded] = useState(false);
    const listQuestions = useSelector(state => state.questions ? state.questions : []);
    const type = useSelector(state => state.type);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const [inputName, setInputName] = useState("default");
    const [layoutTye, setLayoutTye] = useState(layoutDefaultType)
    const keyboard = useRef();
    const [layoutName, setLayoutName] = useState("default");
    const listInputs = ["question"];
    const { ref, isVisible, setIsVisible } = useVisible(false, listInputs);
    const [top, setTop] = useState(0);
    const questionRef = useRef();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{ok}") {
            setIsVisible(false);
        };
    };

    const getInputValue = inputName => {
        return inputs[inputName] || "";
    };

    const onChangeAll = inputs => {
        setInputs({ ...inputs });
    };

    const handleShift = () => {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    };

    const onChangeInput = event => {
        const inputVal = event.target.value;
        setInputs({
            ...inputs,
            [inputName]: inputVal
        });
        keyboard.current.setInput(inputVal);
    };

    const onFocus = (name, refInput, type = layoutDefaultType) => {
        setInputName(name);
        setLayoutTye(type);
        setIsVisible(true);
        setTop(refInput.current.getBoundingClientRect().top);
    }

    const scrollToRef = refInput => {
        refInput.current.scrollIntoView();
    }

    const send = () => {
        dispatch(sendQuestion(questionId, getInputValue("question"), questionId ? "question": "creation"))
    };

    return (
        <>
            {type === "quitBorne" && <DialogRefresh text="Nous vous remercions." />}
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Poser ma question</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Déposez ici votre demande
                </Typography>
            </div>
            <div className="wrap-content-page" style={{position: "relative"}}>
                <div className={classes.fullHeight}>
                    {listQuestions &&
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Question</InputLabel>
                        <Select
                            labelId="select-question-label"
                            id="select-question"
                            value={questionId}
                            onChange={e=>setQuestionId(e.target.value)}
                            label="Age"
                            className={classes.select}
                        >
                            <MenuItem value="">
                                <em>Question</em>
                            </MenuItem>
                            {listQuestions.map((item, index) => (
                                <MenuItem key={index} value={item.id_type_question}>{item.nom_question}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    }
                    <Link className={classes.link} onClick={handleExpandClick}>
                        Ma question ne figure pas dans celles-ci dessus
                    </Link>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <TextField
                            className={classes.input}
                            id="text-question"
                            label="Veuillez saisir votre question"
                            variant="outlined"
                            value={getInputValue("question")}
                            ref={questionRef}
                            name="question"
                            onFocus={() => onFocus("question", questionRef)}
                            onChange={onChangeInput}
                            onClick={()=>scrollToRef(questionRef)}
                            autoComplete="no"
                        />
                    </Collapse>
                </div>
                <div className={classes.wrapBtn}>
                    <div className={classes.wrapKeyBoard} style={!isVisible ? {display: 'none'} : {display: 'block', top: top}} ref={ref}>
                        <Keyboard
                            keyboardRef={r => (keyboard.current = r)}
                            onChangeAll={onChangeAll}
                            layoutName={layoutName}
                            onKeyPress={onKeyPress}
                            inputName={inputName}
                            layout={layoutTye}
                            theme={"hg-theme-default myTheme1"}
                            disableCaretPositioning={true}
                            display= {{
                                "{shift}": "⇧",
                                "{bksp}": "⌫",
                                "{alt}": ".?123",
                                "{smileys}": "\uD83D\uDE03",
                                "{shiftactivated}": "⇧",
                                "{enter}": "return",
                                "{altright}": ".?123",
                                "{downkeyboard}": "🞃",
                                "{space}": "⌴",
                                "{default}": "ABC",
                                "{back}": "⇦",
                                "{ok}": "ok"
                            }}
                            preventMouseDownDefault={true}
                        />
                    </div>
                    <Footer banner={false}>
                        <Button
                            type="submit"
                            title="Submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={send}
                        >
                            Envoyer
                        </Button>
                    </Footer>
                </div>
            </div>
        </>
    )
}
