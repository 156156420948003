import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import HomePage from './pages/HomePage';
import CarteVitalePage from './pages/CarteVitalePage';
import Loading from './components/Common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import InfosPatient from './pages/InfosPatient';
import ListPersons from './pages/ListPersons';
import MenuPage from './pages/MenuPage';
import Appointment from './pages/Appointment';
import {
    getAppointments,
    getAvailabilitiesDoctor,
    getDetailAppointment,
    getFiles,
    getLocations,
    getCategories,
    getQuestions,
    getTypesFile,
    getProfessionsByLocation,
    getReasonsByLocation,
    getDoctorsReason,
    getConfig,
} from './utils/fetcher';
import DetailAppointment from './pages/DetailAppointment';
import OneAppointment from './components/layout/OneAppointment';
import CustomerArea from './pages/CustomerArea';
import MyFiles from './pages/MyFiles';
import RequestFile from './pages/RequestFile';
import MakeRequest from './pages/MakeRequest';
import Question from './pages/Question';
import Specialities from './pages/Specialities';
import LastDoctor from './pages/LastDoctor';
import ListDoctors from './pages/ListDoctors';
import ListCategories from './pages/ListCategories';
import AvailabilitiesDoctor from './pages/AvailabilitiesDoctor';
import Reasons from './pages/Reasons';
import LocationsAppointment from './pages/LocationsAppointment';
import ValidConsultation from './pages/ValidConsultation';
import Prepayment from './pages/Prepayment';
import Summary from './pages/Summary';
import { setAuthenticate, setCurrentPatient } from './actions';

export default function RouterAlx() {
    const dispatch = useDispatch();
    const appointments = useSelector((state) => state.appointments);
    const settings = useSelector((state) => state.settings);

    const getAppointmentsFunc = (to, from, next) => {
        if (!appointments.length) {
            dispatch(getAppointments());
        }
        next();
    };

    const getDetailAppointmentFunc = (to, from, next) => {
        dispatch(getDetailAppointment());
        next();
    };

    const getFilesFunc = (to, from, next) => {
        dispatch(getFiles());
        next();
    };

    const getTypeFilesFunc = (to, from, next) => {
        dispatch(getTypesFile());
        next();
    };

    const getQuestionsFunc = (to, from, next) => {
        dispatch(getQuestions());
        next();
    };

    const getProfessionsFunc = (to, from, next) => {
        dispatch(getProfessionsByLocation());
        next();
    };

    const getAvailabilitiesDoctorFunc = (to, from, next) => {
        dispatch(getAvailabilitiesDoctor());
        next();
    };

    const getReasonsFunc = (to, from, next) => {
        dispatch(getReasonsByLocation());
        next();
    };

    const getLocationsFunc = (to, from, next) => {
        dispatch(getLocations());
        next();
    };

    const getCategoriesFunc = (to, from, next) => {
        dispatch(getCategories());
        next();
    };

    const getDoctorsFunc = (to, from, next) => {
        dispatch(getDoctorsReason());
        next();
    };

    const resetInfoPatients = (to, from, next) => {
        dispatch(setAuthenticate(false));
        dispatch(setCurrentPatient({}));
        next();
    };

    const checkConfig = async (to, from, next) => {
        if(Object.keys(settings).length === 0) {
            dispatch(getConfig());
            next.redirect('/')
        }else{
            next();
        }
    }
    

    return (
        <HashRouter>
            <GuardProvider loading={Loading} guards={[checkConfig]}>
                <Switch>
                    <GuardedRoute exact path="/" guards={[resetInfoPatients]} component={HomePage} />
                    <GuardedRoute exact path="/id_secu/:id_secu"  component={CarteVitalePage} />
                    <GuardedRoute exact path="/infos" component={InfosPatient} />
                    <GuardedRoute exact path="/list-persons" component={ListPersons} />
                    <GuardedRoute exact path="/menu" component={MenuPage} />
                    <GuardedRoute exact path="/rendez-vous" component={Appointment} guards={[getAppointmentsFunc]} />
                    <GuardedRoute exact path="/info-rendez-vous" component={OneAppointment} />
                    <GuardedRoute exact path="/detail-rendez-vous" component={DetailAppointment} guards={[getDetailAppointmentFunc]} />
                    <GuardedRoute exact path="/recap-rendez-vous" component={Summary} />
                    <GuardedRoute exact path="/mon-espace" component={CustomerArea} />
                    <GuardedRoute exact path="/documents" component={MyFiles} guards={getFilesFunc} />
                    <GuardedRoute exact path="/demande-documents" component={RequestFile} guards={getTypeFilesFunc} />
                    <GuardedRoute exact path="/demande" component={MakeRequest} />
                    <GuardedRoute exact path="/question" component={Question} guards={getQuestionsFunc} />
                    <GuardedRoute exact path="/specialites" component={Specialities} guards={getProfessionsFunc} />
                    <GuardedRoute exact path="/categories" component={ListCategories} guards={getCategoriesFunc} />
                    <GuardedRoute exact path="/praticien" component={LastDoctor} />
                    <GuardedRoute exact path="/praticiens" component={ListDoctors} guards={getDoctorsFunc} />
                    <GuardedRoute exact path="/disponibilites" component={AvailabilitiesDoctor} guards={getAvailabilitiesDoctorFunc} />
                    <GuardedRoute exact path="/motifs" component={Reasons} guards={getReasonsFunc} />
                    <GuardedRoute exact path="/lieux" component={LocationsAppointment} guards={getLocationsFunc} />
                    <GuardedRoute exact path="/valider-consultation" component={ValidConsultation} />
                    <GuardedRoute exact path="/prepaiement" component={Prepayment} />
                </Switch>
            </GuardProvider>
        </HashRouter>
    );
}
