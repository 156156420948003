import {
    APPOINTMENT_DETAIL,
    APPOINTMENTS,
    AUTHENTICATE,
    AVAILABILITIES,
    CURRENT_LOCATION,
    CURRENT_PATIENT,
    CURRENT_REASON,
    DATA_PRINT,
    DOCTOR,
    DOCTORS,
    FILES,
    INFO_PATIENTS,
    IS_LOADING,
    LOCATIONS,
    PROFESSIONS,
    QUESTIONS,
    REASONS,
    SET_MESSAGE_WITH_CODE,
    SETTINGS,
    TOKEN,
    TOKEN_MIKAJAKI,
    TYPE,
    TYPE_FILES,
    CATEGORIES
} from "./action-types";

export const settings = configs => ({
    type: SETTINGS,
    configs
});

export const patients = infos => ({
    type: INFO_PATIENTS,
    infos
});

export const setMessageWithCode = data => ({
    type: SET_MESSAGE_WITH_CODE,
    data
});

export const token = infos => ({
    type: TOKEN,
    infos
});

export const setTokenMikajaki = token => ({
    type: TOKEN_MIKAJAKI,
    token
});

export const setAppointments = data => ({
    type: APPOINTMENTS,
    data
});

export const setDoctors = data => ({
    type: DOCTORS,
    data
});

export const setDoctor = data => ({
    type: DOCTOR,
    data
});

export const setType = value => ({
    type: TYPE,
    value
});

export const setProfessions = data => ({
    type: PROFESSIONS,
    data
});

export const isLoading = data => ({
    type: IS_LOADING,
    data
});

export const setAuthenticate = data => ({
    type: AUTHENTICATE,
    data
});

export const setAvailabilities = data => ({
    type: AVAILABILITIES,
    data
});

export const setQuestions = data => ({
    type: QUESTIONS,
    data
});

export const setTypeFiles = data => ({
    type: TYPE_FILES,
    data
});

export const setFiles = data => ({
    type: FILES,
    data
});

export const setDataPrint = data => ({
    type: DATA_PRINT,
    data
});

export const setCurrentPatient = data => ({
    type: CURRENT_PATIENT,
    data
});

export const setCurrentAppointment = data => ({
    type: APPOINTMENT_DETAIL,
    data
});

export const setReasons = data => ({
    type: REASONS,
    data
});

export const setCurrentReason = data => ({
    type: CURRENT_REASON,
    data
});

export const setLocations = data => ({
    type: LOCATIONS,
    data
});

export const setCurrentLocation = data => ({
    type: CURRENT_LOCATION,
    data
});

export const setCategories = data => ({
    type: CATEGORIES,
    data
})
