import ky from "ky";
import {
    isLoading,
    patients,
    setAppointments,
    setAvailabilities,
    setCategories,
    setCurrentAppointment,
    setCurrentPatient,
    setDoctor,
    setDoctors,
    setFiles, setLocations,
    setMessageWithCode,
    setProfessions,
    setQuestions, setReasons,
    settings,
    setTokenMikajaki,
    setType,
    setTypeFiles,
} from "../actions";
import {createHashHistory} from 'history';
import {store} from "../store";
import DateFnsUtils from "@date-io/date-fns";
import {fr} from 'date-fns/locale'
import {RESET_APP} from "../actions/action-types";
import { setAuthenticate } from "../actions";
const uniqid = require("uniqid");

let configs = {};
export const getConfig = () => {
    return dispatch => {
        fetch("config.json")
            .then(response => response.json())
            .then(json => {
                configs = json;
                dispatch(getSettings(configs));
            })
    }
}

let _fetcher;
let _fetcherWidgetRdv;
let loaderTimer;

const fetcher = () => {
    if (!_fetcher) {
        _fetcher = ky.extend({
            //prefixUrl: window.location.origin,
            prefixUrl: configs.url_serveur,
            retry: 1,
            credentials: "same-origin",
            limit: 20,
            timeout: 30000,
            hooks: {
                beforeRequest: [
                    async request => {
                        loaderTimer && clearTimeout(loaderTimer);
                        store.dispatch(isLoading(true));
                        await getToken();
                        request.headers.set("x-alx-idc", configs.identifiant_centre);
                        request.headers.set("Authorization", `${configs.client_id} ${sessionStorage.getItem('token')}`);
                    }
                ],
                afterResponse: [
                    () => {
                        loaderTimer = setTimeout(() => store.dispatch(isLoading(false)), 200);
                    }
                ]
            }
        });
    }
    return _fetcher;
};

export const fetcherWidgetRdv = () => {
    if (!_fetcherWidgetRdv) {
        const searchParams = {cookie: uniqid(), idc: configs.identifiant_centre};

        _fetcherWidgetRdv = ky.extend({
            //prefixUrl: window.location.origin,
            prefixUrl: configs.url_serveur_widget_rdv,
            retry: 1,
            limit: 20,
            timeout: 30000,
            searchParams: searchParams,
            credentials: "same-origin",
        });
    }
    return _fetcherWidgetRdv;
};

export const getPageToRedirect = () => {
    let goto = "";
    switch (store.getState().type) {
        case "haveAppointment":
            goto = "/infos";
            break;
        case "makeAppointment":
            //goto = "/widgetApp";
            goto = "/categories";
            break;
        case "consultationWithoutAppointment":
            //goto = "/widgetApp";
            goto = "/specialites";
            break;
        case "mySpace":
            goto = "/mon-espace";
            break;
        default:
            goto = "/infos";
    }
    return goto;
}

async function getToken() {
    if (!sessionStorage.getItem('date') || sessionStorage.getItem('date') < new Date()) {
        const formData = new FormData();
        formData.append('login', configs.client_id);
        formData.append('password', configs.secret_id);

        const response = await ky.post('https://central.alaxione.fr/api_user/authentification', {body: formData});
        const json = await response.json();
        if (json.result === 'ok') {
            sessionStorage.setItem('date', new Date().getTime() + configs.lifetimeToken);
            sessionStorage.setItem('token', json.token);
        }
    }
}

export const getSettings = (configs = {}) => {
    /*pour la demo to delete*/
    const urlParams = new URLSearchParams(window.location.search);
    const vitalCardId = urlParams.has('cartevitale') ? urlParams.get('cartevitale') : "";
    const name = urlParams.has('nom') ? urlParams.get('nom') : "";
    const lastname = urlParams.has('prenom') ? urlParams.get('prenom') : "";
    /*pour la demo to delete*/
    return (dispatch) => {
        fetcher()
            .get('api_entite/entite')
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    const data = json.tabinfo[0];
                    const [adress, zipCode, city, logo, additionInfo, isShowQrCode, locationId, reasonId, isRequiredPrepayment, sum, isUpdatePatient] = [data.adresse_entite, data.code_postal_entite, data.ville_entite, data.site_logo_entite, data.phrase_bas_widget_entite_nohtml, data.bqrcode_rdv_entite, json.tabinfo.id_lieu, json.tabinfo.id_motif, json.tabinfo.is_paiement, json.tabinfo.montant, json.activation_borne_maj_patient_entite]
                    dispatch(settings({...configs,firstName:name,lastName:lastname, adress: adress, zipCode: zipCode, city: city, logo: logo, additionInfo: additionInfo, isShowQrCode: isShowQrCode, locationId: locationId, reasonId: reasonId, isRequiredPrepayment: isRequiredPrepayment, sum: sum, vitalCardId: vitalCardId, isUpdatePatient: isUpdatePatient}));
                }
            })
    }
}

export const authenticate = (data) => {
    let history = createHashHistory();

    const formData = new FormData();
    formData.append('tab[]', data);

    return (dispatch) => {
        dispatch(setType('haveAppointment')); // choix : Prendre rendez-vous par défaut
        fetcher()
            .post(`api_patient/auth_secu`, {body: JSON.stringify({tab : data})})
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    if (json.tabinfo.length) {
                        json.tabinfo.map(item => {
                            if (item.datenaissance) {
                                const newDateFormat = item.datenaissance.replaceAll('/','');
                                item.datenaissance = newDateFormat;
                            }
                            return item;
                        });
                        dispatch(patients(json.tabinfo));
                        dispatch(setAuthenticate(true))
                        if (json.tabinfo.length > 1) {
                            history.push('/list-persons')
                        } else {
                            dispatch(setCurrentPatient(json.tabinfo[0]));
                            if (json.tabinfo[0].rdv_today === 0) {
                                dispatch(setType("noAppointment"));
                            }
                            const goTo = getPageToRedirect();
                            history.push(goTo);
                        }
                    }
                    dispatch(setMessageWithCode({}));
                } else {
                    dispatch(setAuthenticate(false))
                    dispatch(setMessageWithCode({text: json.message, code: "authenticate"}));
                }
            })
    }
}

export const getPatient = (values) => {
    let history = createHashHistory();
    return async (dispatch, getState) => {
        const {settings} = await getState();
        fetcher()
            .get('api_patient/patientNew', {
                searchParams: {
                    ...values,
                    id_entite: settings.identifiant_centre,

                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.result === 'ok' || json.tablist.length >= 1){
                    dispatch(patients(json.tablist));
                    dispatch(setCurrentPatient(json.tablist[0]));
                    
                    _getAppointments(dispatch, getState)
                    .then(appointments => {
                        if (!appointments.length) {
                            history.push('/menu')
                        } else {
                            history.push('/rendez-vous');
                        }
                    })

                }else{
                    dispatch(setMessageWithCode({text: "Vos informations ne sont pas correcte, merci de bien vouloir y remédier", code:  'patient'}));
                    dispatch(setType("quitBorne"));
                    setTimeout(() => {dispatch(logout())}, 5000);
                }
            })
    }
}

export const setPatient = (values) => {
    let history = createHashHistory();
    return (dispatch, getState) => {
        dispatch(setType('haveAppointment'));
        fetcher()
            .get(`api_patient/patient`, {
                    searchParams: {
                        ...values,
                        allrdv: 'TODAY'
                    }
                }
            )
            .then(response => response.json())
            .then(json => {

                if (json.tabinfo.length) {
                    //dispatch(patients(json.tabinfo));
                    if (json.tabinfo.length > 1) {
                        history.push('/list-persons')
                    } else {
                        dispatch(setCurrentPatient(json.tabinfo[0]));
                        _getAppointments(dispatch, getState)
                            .then(appointments => {
                                if (!appointments.length) {
                                    dispatch(setType("quitBorne"));
                                    setTimeout(() => {dispatch(logout())}, 5000);
                                } else {
                                    history.push(getPageToRedirect());
                                }
                            })
                    }
                    dispatch(setMessageWithCode({}));
                } else {
                    if (json.rdv === 0) {
                        dispatch(setType("quitBorne"));
                        setTimeout(() => {dispatch(logout())}, 5000)
                    } else {
                        dispatch(setMessageWithCode({text: "Vos informations ne sont pas correcte, merci de bien vouloir y remédier", code:  'patient'}));
                    }
                }
            });
    }
}

export const editPatient = (values, id, haveAppointment) => {
    let history = createHashHistory();
    if(values.nom_naissance_patient){
        values['nom_jeune_fille_patient'] = values.nom_naissance_patient;
    }
    return dispatch => {

        fetcher()
            .put(`api_patient/patient/${id}`, {
                    body: JSON.stringify(values)
                }
            )
            .then(response => response.json())
            .then(json => {
                dispatch(setMessageWithCode({text: json.message, code: json.result}));
                if (json.result === "ok") {
                    //dispatch(patients(json.tabinfo));
                    if(haveAppointment){
                        history.push('/rendez-vous')
                    } else {
                        history.push('/menu')
                    }
                }
            });
    }
}

export const getAppointments = () => {
    return (dispatch, getState) => {
        _getAppointments(dispatch, getState);
    }
}

const _getAppointments = (dispatch, getState) => {
    const patientId = getState().currentPatient.guid_patient;
    const searchParams = new URLSearchParams();
    searchParams.append('listpers[]', patientId);

    return fetcher()
        .get(`api_patient/rdv`, {
            searchParams
        })
        .then(response => response.json())
        .then(json => {
            if (json.result === "ok") {
                dispatch(setAppointments(json.tabinfo));
                if (json.tabinfo.length === 1) {
                    dispatch(setCurrentAppointment(json.tabinfo[0]));
                }else if(json.tabinfo.length === 0){
                    dispatch(setType('noAppointment'));
                }
                //dispatch(appointmentDetail(json.tabinfo[0])); //  on gére que le cas d'une seule personne
            }

            if (!json.tabinfo || (json.tabinfo && !json.tabinfo.length)) {
                dispatch(setMessageWithCode({text: "Vous n'avez aucun rendez-vous prévu.", code: "listAppointment"}));
            }

            return json.tabinfo;
        })
}

export const getDetailAppointment = () => {
    const dateUtils = new DateFnsUtils({locale: fr});
    return (dispatch, getState) => {
        const id = getState().currentAppointment.identification_rdv;
        fetcher()
            .get(`api_rdv/rdv/${id}?typeprovenance=borne`)
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    const date = dateUtils.parse(json.tabinfo[0].date_rdv + ' ' + json.tabinfo[0].heure_aff_rdv, "yyyy-MM-dd HH:m:s");
                    json.tabinfo[0].date_rdv = dateUtils.format(date, "dd MMM yyyy");
                    json.tabinfo[0].heure_aff_rdv = dateUtils.format(date, "HH:mm");
                    dispatch(setCurrentAppointment(json.tabinfo[0]));
                }
            })
    }
}

export const setWaitingRoom = (appointmentsSelected) => {
    const history = createHashHistory();

    return dispatch => {
        //const isRequiredPayment = getState().settings.isRequiredPrepayment;
        fetcher()
            .put(`api_salle/salle`, {
                body: JSON.stringify({
                    statut: 'patientensalle',
                    listrdv: [appointmentsSelected.identification_rdv]
                })
            })
            .then (response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    // appointmentsSelected[0] provisiore en attendant la réponse de la redirection pour plusieurs rendez-vous
                    dispatch(setAppointmentTreated(appointmentsSelected));
                    history.push('/detail-rendez-vous');
                }
            })
    }
}

const setAppointmentTreated = appointment => {
    return(dispatch, getState) => {
        const appointmentsTreated = getState().appointments.map(item => {
            if (item.identification_rdv === appointment.identification_rdv) {
                item['isTreated'] = true;
            }
            return item;
        });
        dispatch(setAppointments(appointmentsTreated));
    }
}

export const openMikajaki = appointment => {
    return dispatch => {
        fetcher()
        ky.post(`${configs.urlMikajaki}/registerAlaxionePatient`, {
            body: JSON.stringify({
                'patientId': appointment.guid_patient,
                'firstName': appointment.nom_patient,
                'surname': appointment.prenom_patient,
                'birthDate': appointment.date_naissance_patient,
                'reasonApointment': '',
                'date': appointment.date_rdv,
                'hour': appointment.heure_debut_rdv,
                'appointmentId': appointment.identification_rdv,
                'gender': appointment.nom_cvilite,
                'email': appointment.email_patient
            })
        })
            .then(response => response.json())
            .then(token => {
                dispatch(setTokenMikajaki(token));
            });
    }
}

export const closeMikajaki = appointment => {
    return dispatch => {
        let history = createHashHistory();
        let goto = ""
        switch (appointment.is_payable) {
            case "ok":
                goto = '/valider-consultation';
                break
            default:
                goto = `/detail-rendez-vous`;
                break;
        }
        dispatch(setTokenMikajaki(""));
        dispatch(setAppointmentTreated(appointment));
        history.push(goto);
    }
}

export const printPaper = data => {
    const history = createHashHistory();
    return (dispatch, getState) => {
        fetch(`${configs.urlPrint}/print_ticket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(json => {
                if (!json.error) {
                    const appointmentsTreated = getState().appointments.filter(item => item.isTreated);
                    if (appointmentsTreated.length < getState().appointments.length) {
                        history.push('/rendez-vous');
                    } else if (!getState().isAuthenticate) {
                        dispatch(setType("quitBorne"));
                        setTimeout(() => {dispatch(logout())}, 5000)
                    } else {
                        dispatch(setAppointments([]));
                        dispatch(setPatientTreated());
                        dispatch(setType("refresh"));
                    }

                } else {
                    dispatch(setMessageWithCode({code: 'print', text: json.message}));
                }
            });
    }
}

const setPatientTreated = () => {
    return(dispatch, getState) => {
        const patient = getState().currentPatient;
        const patientsTreated = getState().patients.map(item => {
            if (item.guid_patient === patient.guid_patient) {
                item['rdv_today'] = 0;
            }
            return item;
        });
        dispatch(patients(patientsTreated));
    }
}

export const logout = () => {
    return dispatch => {
        let history = createHashHistory();
        history.push('/');
        dispatch({ type: RESET_APP });
        dispatch(getConfig());
    }
}

/*Espace patient*/
export const getFiles = () => {
    const dateUtils = new DateFnsUtils({locale: fr});
    return (dispatch, getState) => {
        fetcher()
            .get(`api_patient/document?id_patient=${getState().currentPatient.id_patient}`)
            .then(response => response.json())
            .then(json => {
                if (json.tabinfo) {
                    const list = json.tabinfo.map(item => {
                        const dateenreg = dateUtils.parse(item.dateenreg, "dd/MM/yyyy HH:mm");
                        const lastPrintFile = dateUtils.parse(item.last_print_file, "dd/MM/yyyy HH:mm");
                        item.dateenreg = dateUtils.format(dateenreg, "dd MMM yyyy");
                        item.last_print_file = dateUtils.isValid(lastPrintFile) ? dateUtils.format(lastPrintFile, "dd MMM yyyy") : item.last_print_file;
                        return item;
                    });

                    dispatch(setFiles(list));
                }
            })
    }
}

export const printPdf = (file, fileId) => {
    return dispatch => {
        const token = sessionStorage.getItem('token');
        file = `${file}&token=${token}`;
        fetch(`${configs.urlPrint}/print_pdf`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({listFiles: [file]})
        }).then(response => response.json())
            .then(json => {
                dispatch(setMessageWithCode({code: 'print', text: json.message}));
                setNbrPrintPdf(fileId);

            });
    }
}

const setNbrPrintPdf = fileId => {
    fetcher()
        .put('api_patient/nbr_impression_file', {
            body: JSON.stringify({
                id_file_rdv: fileId
            })
        })
        .then(response => response.json())
        .then( () => {
            console.log('OK');
        });
}

export const sendQuestion = (questionId = null, question="", request = "", documentId = null) => {
    const formData = new FormData();
    return (dispatch, getState) => {
        formData.append('id_patient', getState().currentPatient.id_patient);
        formData.append('id_type_question', questionId);
        formData.append('id_type_document', documentId);
        formData.append('demande', request);
        formData.append('laquestion', question);
        fetcher()
            .post('api_question/demande', {
                body: formData
            })
            .then(response => response.json())
            .then(() => {
                dispatch(setType("quitBorne"));
                //dispatch(setMessageWithCode({text: json.message, code: json.result === "ok" ? 1 : 0}));
            })
    }
}

export const getTypesFile = () => {
    return dispatch => {
        fetcher()
            .get('api_document/document')
            .then(response => response.json())
            .then(json => {
                if (json.tabinfo) {
                    dispatch(setTypeFiles(json.tabinfo));
                }
            })
    }
}

export const getQuestions = () => {
    return dispatch => {
        fetcher()
            .get('api_question/question')
            .then(response => response.json())
            .then(json => {
                if (json.tabinfo) {
                    dispatch(setQuestions(json.tabinfo));
                }
            })
    }
}
/*Espace patient*/

/*Make appointments*/
export const getProfessions = () => {
    return dispatch => {
        fetcher()
            .get('api_profession/profession')
            .then(response => response.json())
            .then(json => {
                dispatch(setProfessions(json.tablist));
            })
    }
}

export const getProfessionsWidget = (id) => {
    return dispatch => {
        fetcherWidgetRdv()
            .get('widget_liste_profession.php', {
                searchParams: {
                    provenance: "web",
                    idcatprofession:id
                }
            })
            .then(response => response.json())
            .then(json => {
                dispatch(setProfessions(json));
            })
    }
}

export const getProfessionsByLocation = () => {
    return (dispatch, getState) => {
        const {settings} = getState();
        fetcher()
            .get('api_profession/profByLocation', {
                searchParams: {
                    id_lieu: settings.locationId,
                    id_entite: settings.identifiant_centre,
                    id_borne: settings.client_id,
                    isborne: "1"
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.tablist){
                    dispatch(setProfessions(json.tablist));
                }
            })
    }
}

export const getReasonsByLocation = () => {
    return (dispatch, getState) => {
        const {settings} = getState();
        fetcher()
            .get('api_profession/motifByLocation', {
                searchParams: {
                    id_lieu: settings.locationId,
                    id_entite: settings.identifiant_centre,
                    id_borne: settings.client_id,
                    isborne: "1"
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.tablist){
                    dispatch(setReasons(json.tablist));
                }
            })
    }
}

export const getCategories = () => {
    const history = createHashHistory();
    return dispatch => {
        fetcherWidgetRdv()
            .get('widget_liste_categorie.php')
            .then(response => response.json())
            .then(json => {
                dispatch(setCategories(json));
                history.push('/categories');
            })
    }
}

export const getDoctorsReason = () => {
    return (dispatch, getState) => {
        const {settings,currentReason} = getState();
        fetcher()
        .get('api_profession/praticienByLocation', {
            searchParams: {
                id_lieu: settings.locationId,
                id_entite: settings.identifiant_centre,
                id_reason: currentReason.id,
                id_borne: settings.client_id,
                isborne: "1"
            }
        })
        .then(response => response.json())
        .then(json => {
            if(json.tablist && json.result === "ok"){
                dispatch(setDoctors(json.tablist));
                dispatch(setMessageWithCode({}))
            }else{
                dispatch(setDoctors([]));
                dispatch(setMessageWithCode({text: json.message, code: 'noAvailabilities'}))
            }
        })
    }
}

export const getDoctors = id => {
    const history = createHashHistory();
    return (dispatch, getState) => {
        fetcherWidgetRdv()
            .get('widget_dernier_praticien.php', {
                searchParams: {
                    id_patient: getState().currentPatient.id_patient,
                    guid_patient: getState().currentPatient.guid_patient,
                    id_profession: id
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok" && json.tabinfo) {
                    dispatch(setDoctors(json.tabinfo));
                    if (json.tabinfo.last && json.tabinfo.last.length > 0) {
                        history.push('/praticien');
                    } else if (json.tabinfo.all && json.tabinfo.all.length > 0){
                        if (json.tabinfo.all.length === 1) {
                            dispatch(setDoctor(json.tabinfo.all[0]))
                            history.push(`/disponibilites?id=${json.tabinfo.all[0].guid_praticien}`);
                        } else {
                            history.push('/praticiens');
                        }
                    }
                    //@todo : else history.push(`/liste des rendez-vous`);
                }
            })
    }
}

export const getAvailabilitiesDoctorOld = () => {
    const now = new Date();
    const dateUtils = new DateFnsUtils({locale: fr});

    return (dispatch, getState) => {
        const id = getState().doctor.guid_praticien;
        fetcher()
            .get(configs.apiAvailabilities, {
                searchParams: {
                    id_motif: getState().currentReason ? getState().currentReason.id : getState().settings.reasonId,
                    id_lieu: getState().currentLocation ? getState().currentLocation.id : getState().settings.locationId,
                    nb_personne: 1, // pour le moment ongère que le cas d'une seule personne
                    'aTabpraticien[]': id,
                    idjour: "",
                    nbr_affiche : getState().type === "consultationWithoutAppointment" ? 1 : "",
                    creneauhoraire : "",
                    datedebut : getState().type === "consultationWithoutAppointment" ? now.getFullYear()  + "-" + (now.getMonth()+1) + "-" + now.getDate() : "",
                    datefin : getState().type === "consultationWithoutAppointment" ? now.getFullYear()  + "-" + (now.getMonth()+1) + "-" + now.getDate() : "",
                    type_proposition : "",
                    id_motif_aide : "",
                    delai_min_aide : "",
                    delai_max_aide : ""
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.tablist.length > 0) {
                    const list = json.tablist.map(item => {
                        const date = dateUtils.parse(item.dateeng, "yyyy-MM-dd");
                        item.dateaff = dateUtils.format(date, "dd MMM yyyy");
                        return item;
                    });
                    dispatch(setAvailabilities(list));
                    dispatch(setMessageWithCode({}))
                } else {
                    dispatch(setMessageWithCode({text: 'aucun créneaux correspondant à vos critères n\'est disponible', code: 'noAvailabilities'}));
                }
            })
    }
}

export const getAvailabilitiesDoctor = () => {
    const dateUtils = new DateFnsUtils({locale: fr});

    return (dispatch, getState) => {
        fetcher()
            .get("api_widget/get_rdv_disponible_ajax", {
                searchParams: {
                    idp: getState().doctor ? getState().doctor.guid_praticien : "",
                    idtyperdv: getState().currentReason.id ? getState().currentReason.id : getState().settings.reasonId,
                    idlieux: getState().currentLocation.id ? getState().currentLocation.id : getState().settings.locationId,
                    nbpersonne: 1
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json[0].success === 0) {
                    dispatch(setMessageWithCode({text: 'aucun créneaux correspondant à vos critères n\'est disponible', code: 'noAvailabilities'}));
                } else {                    
                    const list = json.map(item => {
                        const dateFr = item.dateaff.split('/');
                        const date = dateUtils.parse(dateFr[2] + '-' + dateFr[1] + '-' + dateFr[0], "yyyy-MM-dd");
                        item.dateaff = dateUtils.format(date, "dd MMM yyyy");
                        return item;
                    });
                    dispatch(setAvailabilities(list));
                    dispatch(setMessageWithCode({}))
                }
            })
    }
}

export const getAvailabilities = () => {
    const dateUtils = new DateFnsUtils({locale: fr});

    return (dispatch, getState) => {
        fetcherWidgetRdv()
            .get("api_widget/get_rdv_disponible_ajax", {
                searchParams: {
                    idp: getState().doctor ? getState().doctor.guid_praticien : "",
                    idtyperdv: getState().currentReason ? getState().currentReason.id : getState().settings.reasonId,
                    idlieux: getState().currentLocation ? getState().currentLocation.id : getState().settings.locationId,
                    nbpersonne: 1
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json[0].success === 0) {
                    dispatch(setMessageWithCode({text: 'aucun créneaux correspondant à vos critères n\'est disponible', code: 'noAvailabilities'}));
                } else {                    const list = json.map(item => {
                        const dateFr = item.dateaff.split('/');
                        const date = dateUtils.parse(dateFr[2] + '-' + dateFr[1] + '-' + dateFr[0], "yyyy-MM-dd");
                        item.dateaff = dateUtils.format(date, "dd MMM yyyy");
                        return item;
                    });
                    dispatch(setAvailabilities(list));
                    dispatch(setMessageWithCode({}))
                }
            })
    }
}

export const saveAppointmentOld = appointment => {
    const formData = new FormData();
    const history = createHashHistory();
    return (dispatch, getState) => {
        formData.append('id_patient', Object.keys(getState().currentPatient).length ? getState().currentPatient.guid_patient : getState().patients[0].guid_patient);
        formData.append('heure_debut_rdv', appointment.heure_debut);
        formData.append('date_rdv', appointment.dateeng);
        formData.append('id_praticien', getState().doctor.guid_praticien);
        formData.append('id_lieu', getState().settings.locationId);
        formData.append('id_motif', getState().settings.reasonId);
        formData.append('id_planning', appointment.idplanning);
        formData.append('heure_aff_rdv', appointment.heureaff);
        formData.append('heure_fin_rdv', "");
        formData.append('idgrouperecurrent_rdv', "");
        formData.append('idexterne_rdv', "");
        formData.append('temps_attente_rdv', "");
        formData.append('date_saisi_rdv', "");
        formData.append('timestamp_debut_rdv', appointment.startdate);
        formData.append('timestamp_fin_rdv', appointment.enddate);
        formData.append('etat_rdv', 'valide');
        formData.append('typeprovenance', 'borne');
        fetcher()
            .post('api_rdv/rdv/', {
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    dispatch(setCurrentAppointment(json.tabinfo[0]));
                    if (!(getState().type === "consultationWithoutAppointment" && appointment.use_mikajaki === "Y")) {
                        let goto = ""
                        switch (appointment.is_payable) {
                            case "ok":
                                goto = '/valider-consultation';
                                break
                            default:
                                goto = `/detail-rendez-vous`;
                                break;
                        }
                        history.push(goto);
                    }
                }
            })
    }
}

export const saveAppointment = appointment => {
    
    return (dispatch, getState) => {

        const appointmentInfo = appointment.id.split('-');
        const date = appointmentInfo[1] + "-" + appointmentInfo[2] + "-" + appointmentInfo[3];
        const hours = appointmentInfo[4] + ":" + appointmentInfo[5] + ":00";
        const locationId = appointmentInfo[6];
        const typeId = appointmentInfo[8];
        const planningId = appointmentInfo[10];
        const practitionerId = appointment.id_long;
        const formData = new FormData();
        let allInformations = {
            id_lieu: locationId,
            heure_debut_rdv: hours,
            date_rdv: date,
            id_docteur: practitionerId,
            id_praticien: practitionerId,
            id_motif: typeId,
            id_provenance: 1,
            id_pere: 1,
            type_rdv: 1,
            id_planning: planningId,
            id_appointment: appointment.id,
            id_patient: Object.keys(getState().currentPatient).length ? getState().currentPatient.guid_patient : getState().patients[0].guid_patient
        };
        formData.append("aTableauRDV", [allInformations]);

        fetcher()
            .post('api_rdv/rdv_multiple', {
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    dispatch(validAppointment());
                }
            })
    }
}

const validAppointment = () => {
    return (dispatch, getState) => {
        fetcherWidgetRdv()
            .get('modification_liste_user_rdv.php', {
                searchParams: {
                    'tab_id_user[]': [getState().currentPatient.guid_patient]
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json[0].success === 1) {
                    dispatch(getSummary());
                }
            })
    }
}

const getSummary = () => {
    const history = createHashHistory();
    const dateUtils = new DateFnsUtils({locale: fr});
    return dispatch => {
        fetcherWidgetRdv()
            .get('recap_rdv_ajax.php')
            .then(response => response.json())
            .then(json => {
                if (json[0]) {
                    const date = dateUtils.parse(json[0].date_prise_rendez_vous, "yyyy-MM-dd");
                    json[0].date_prise_rendez_vous = dateUtils.format(date, "dd MMM yyyy");
                    dispatch(setCurrentAppointment(json[0]));
                    history.push('/recap-rendez-vous');
                }
            })
    }
}

export const getReasons = () => {
    return (dispatch, getState) => {
        fetcherWidgetRdv()
            .get('widget_liste_type_rdv.php', {
                searchParams: {
                    idp: getState().doctor.guid_praticien,
                    all: 1, 
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.length) {
                    dispatch(setReasons(json));
                }
            })
    }
}

export const getReasonsAll = () => {
    const history = createHashHistory();

    return dispatch => {
        fetcherWidgetRdv()
            .get('widget_liste_type_rdv.php',{
                searchParams: {
                    all: 1
                }
            })
            .then(response => response.json())
            .then(json => {
                if(json.length){
                    dispatch(setReasons(json));
                    history.push('/motifs');
                }
            })
    }
}

export const getLocations = () => {
    return (dispatch, getState) => {
        fetcherWidgetRdv()
            .get('liste_lieux.php', {
                searchParams: {
                    idp: getState().doctor.guid_praticien,
                    idtyperdv: getState().currentReason.id
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    dispatch(setLocations(json));
                }
            })
    }
}

export const prePayment = values => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
        formData.append(key, value)
    }
    return (dispatch, getState) => {
        formData.append('montant', getState().currentAppointment.montant_payable);
        fetcher()
            .post(`api_rdv/prepaiement/${getState().currentAppointment.identification_rdv}`, {
                body: formData
            })
            .then(response => response.json())
            .then(json => {
                if (json.result === "ok") {
                    dispatch(setWaitingRoom(getState().currentAppointment));
                } else {
                    dispatch(setMessageWithCode({text: json.message, code: 'payment'}));
                }
            })
    }
};



