import React,{useEffect} from 'react';
import {Button, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Alert, Skeleton} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import carteVitale from "../assets/images/home_page_card.png";
import {authenticate,getPatient} from "../utils/fetcher";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: '10px',
        padding: '6px 36px',
        fontSize: 35
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    wrapText: {
        display: "flex",
        alignItems: "flex-end"
    },
    alignCenter: {
        textAlign: 'center'
    },
    img: {
        marginLeft: 15
    },
    inline: {
        display: 'inline'
    },
    alert: {
        fontSize: "20px",
        marginBottom: "20px",
        alignItems: "center"
    },

}));


export default function CarteVitalePage() {
    const classes = useStyles();
    const {adress, zipCode, city, vitalCardId, firstName, lastName} = useSelector(state => state.settings);
    const message = useSelector(state => state.messageWithCode);
    const dispatch = useDispatch();
    const { id_secu } = useParams();

    useEffect(() => {
        if (id_secu) {
              dispatch(getPatient({ "id_secu": id_secu }));
        }
    }, [dispatch]);

    const goToDemo = () => {
        const authData = [
            {cardVitalId: vitalCardId, nom: firstName, prenom: lastName},
        ]
        dispatch(authenticate(authData));
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Bienvenue dans votre cabinet</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    {adress ? adress : <Skeleton className={classes.inline} /> },&nbsp;
                    {zipCode ? zipCode : <Skeleton className={classes.inline} />}&nbsp;
                    {city ? city : <Skeleton className={classes.inline} />}
                </Typography>
            </div>

            <div className="wrap-content-page">
                <div className={classes.alignCenter}>
                    {message && (message.code === "authenticate") &&
                    <Alert className={classes.alert} severity="error">{message.text}</Alert>
                    }
                    <div className={classes.wrapText}>
                        <div style={{flex:'1'}}></div>
                        <img height="694" src={carteVitale} alt="Carte vitale" className={classes.img} />
                        <div style={{flex:'1'}} className={classes.alignCenter}>
                            {vitalCardId &&
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={goToDemo}
                                >
                                    {"J'ai inséré ma carte vitale"}
                                </Button>
                            }
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                href="#/infos"
                            >
                                {"Je n'ai pas de carte vitale"}
                            </Button>
                        </div>
                    </div>
                    
                </div>
            </div>

            

        </>
    )
}
