import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { saveAppointment } from '../utils/fetcher';
import PreAppointmentDetail from './PreAppointmentDetail';
import Alert from '@material-ui/lab/Alert';
import Footer from '../components/Common/Footer';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    text: {
        fontSize: 40,
        marginBottom: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconCheckboxChecked: {
        fontSize: 61,
        color: theme.palette.primary.main,
    },
    banner: {
        boxShadow: 'none',
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formControlLabel: {
        marginRight: 0,
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
    },
    white: {
        color: 'white',
    },
    alignLeft: {
        textAlign: 'left',
    },
    alert: {
        fontSize: '20px',
        marginTop: '20px',
        alignItems: 'center',
    },
    grid: {
        justifyContent: 'center'
    }
}));

export default function AvailabilitiesDoctor() {
    const classes = useStyles();
    const listAvailabilities = useSelector((state) => (state.availabilities ? state.availabilities : []));
    const type = useSelector((state) => state.type);
    const message = useSelector((state) => state.messageWithCode);
    const dispatch = useDispatch();

    const list = [];
    if (listAvailabilities) {
        listAvailabilities.forEach((item, key) => {
            if (key % 4 === 0) {
                list.push(listAvailabilities.slice(key, key + 6));
            }
        });
    }

    const toggle = (item) => {
        dispatch(saveAppointment(item));
    };

    return (
        <>
            {type === 'consultationWithoutAppointment' ? (
                <PreAppointmentDetail message={message} appointment={listAvailabilities[0]} />
            ) : (
                <>
                    <div className="head-title">
                        <Typography variant="h1" component="h1" className={classes.titleColor}>
                            Prendre mon rendez-vous
                        </Typography>
                        <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                            Choisissez votre rendez-vous.
                        </Typography>
                    </div>
                    <div className="wrap-content-page">
                        <div className="carouselWrap">
                            {list.length > 0 && (
                                <Carousel
                                    navButtonsAlwaysVisible={true}
                                    indicators={false}
                                    autoPlay={false}
                                    className="caroussel"
                                    navButtonsProps={{ className: 'CarouselButtons', disabled: list.length <= 1 }}
                                    navButtonsWrapperProps={{ className: 'CarouselButtonsWrapper' }}
                                >
                                    {list.map((elet, index) => (
                                        <Card raised className={classes.banner} key={index}>
                                            <Grid container className={classes.grid}>
                                                {elet.map((item, key) => (
                                                    <Grid item key={key}>
                                                        <Card className={classes.card}>
                                                            <CardActionArea>
                                                                <CardContent className={classes.cardContent} onClick={() => toggle(item)}>
                                                                    <div className={classes.alignLeft}>
                                                                        <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                                                            <strong>{item.dateaff}</strong>
                                                                        </Typography>
                                                                        <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                                                            à {item.heureaff_patient}
                                                                        </Typography>
                                                                    </div>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Card>
                                    ))}
                                </Carousel>
                            )}
                        </div>
                        {message && message.code === 'noAvailabilities' && (
                            <Alert className={classes.alert} severity="error">
                                {message.text}
                            </Alert>
                        )}
                    </div>
                    <Footer banner={false} />
                </>
            )}
        </>
    );
}
