import React, {useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import {prePayment} from "../utils/fetcher";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import useMask from "react-mask-hook";
import useVisible from "../useVisible";
import {useForm} from "react-hook-form";
import Keyboard from "react-simple-keyboard";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    textField: {
        height: 115
    },
    alignCenter: {
        textAlign: 'center'
    },
    field: {
        height: "115px",
        width: "100%",
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        color: theme.palette.text.primary,
        fontSize: "40px",
        paddingLeft: "12px",
        borderRadius: "4px"
    },
    mt20: {
        marginTop: "20px"
    },
    alert: {
        fontSize: "20px",
        marginTop: "20px",
        alignItems: "center"
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px',
        color: theme.palette.text.primary
    },
    wrapBtns: {
        display: 'flex',
        justifyContent: "space-around",
        marginTop: '40px'
    },
    wrapKeyBoard: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99
    }
}));

export default function Prepayment() {
    const layoutDefaultType = {
        default: [
            "a z e r t y u i o p",
            "q s d f g h j k l m",
            "{shift} w x c v b n {bksp}",
            "- , {space} . {ok}"
        ],
        shift: [
            "A Z E R T Y U I O P",
            "Q S D F G H J K L M",
            "{shift} W X C V B N {bksp}",
            "- , {space} . {ok}"
        ]
    };
    const layoutNumeric = {
        default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {ok}"]
    };
    const classes = useStyles();
    const dispatch = useDispatch();
    const message = useSelector(state => state.messageWithCode);
    const paymentMode = useSelector(state => state.settings.isRequiredPrepayment);

    const [layoutName, setLayoutName] = useState("default");
    const listInputs = ["num_carte", "nom_carte", "date_exp", "cvc"];
    const { ref, isVisible, setIsVisible } = useVisible(false, listInputs);
    const [top, setTop] = useState(0);
    const { register, handleSubmit } = useForm();

    const [inputs, setInputs] = useState({});
    const [inputName, setInputName] = useState("default");
    const [layoutTye, setLayoutTye] = useState(layoutDefaultType)
    const keyboard = useRef();
    const cardNumberRef = useRef();
    const nameRef = useRef();
    const expirationDateRef = useRef();
    const cvcRef = useRef();

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{ok}") {
            setIsVisible(false);
        };
    };

    const getInputValue = inputName => {
        return inputs[inputName] || "";
    };

    const onChangeAll = inputs => {
        if (inputs.num_carte && inputs.num_carte.length > 16) {
            inputs.num_carte = getInputValue("num_carte");
        }

        if (inputs.date_exp && inputs.date_exp.length > 6) {
            inputs.date_exp = getInputValue("date_exp");
        }

        if (inputs.cvc && inputs.cvc.length > 3) {
            inputs.cvc = getInputValue("cvc");
        }
        setInputs({ ...inputs });
    };

    const handleShift = () => {
        setLayoutName(layoutName === "default" ? "shift" : "default");
    };

    const onChangeInput = event => {
        const inputVal = event.target.value;
        setInputs({
            ...inputs,
            [inputName]: inputVal
        });
        keyboard.current.setInput(inputVal);
    };

    const onChangeInputMask = value => {
        setInputs({
            ...inputs,
            [inputName]: value
        });
        keyboard.current.setInput(value);
    };

    const onFocus = (name, refInput, type = layoutDefaultType) => {
        setInputName(name);
        setLayoutTye(type);
        setIsVisible(true);
        setTop(refInput.current.getBoundingClientRect().top);
    }

    const scrollToRef = refInput => {
        refInput.current.scrollIntoView();
    }

    const maskPropsCardNumber = useMask({
        value: getInputValue('num_carte'),
        onChange: onChangeInputMask,
        mask: '#### #### #### ####',
        placeholder: '____ ____ ____ ____'
    });

    const maskPropsDate = useMask({
        value: getInputValue('date_exp'),
        onChange: onChangeInputMask,
        mask: '## / ####',
        placeholder: '__ / ____'
    });

    const maskPropsCsv = useMask({
        value: getInputValue('cvc'),
        onChange: onChangeInputMask,
        mask: '###',
        placeholder: '___'
    });

    const prepayment = values => {
        dispatch(prePayment(values));
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Prépaiement</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Votre carte bancaire ne sera débitée qu'une fois votre consultation terminée. </Typography>
            </div>
            <div className="wrap-content-page" style={{position: 'relative'}}>
                <form onSubmit={handleSubmit(prepayment)}>
                    <Cards
                        number={getInputValue("num_carte") || ''}
                        name={getInputValue("nom_carte") || ''}
                        expiry={getInputValue("date_exp") || ''}
                        cvc={getInputValue("cvc") || ''}
                        focused={inputName}
                        placeholders={{
                            name: "Votre nom"
                        }}
                        locale={{
                            valid: "Date d'expiration"
                        }}
                    />
                    {message && message.code === "payment" &&
                    <Alert className={classes.alert} severity={message.code ? "success" : "error"}>{message.text}</Alert>
                    }
                    <Grid container spacing={7} className={classes.mt20}>
                        <Grid item xs={6}>
                            <TextField
                                label="Numéro de la carte"
                                variant="outlined"
                                type="text"
                                name="num_carte"
                                fullWidth
                                onClick={()=>scrollToRef(cardNumberRef)}
                                autoComplete="no"
                                {...maskPropsCardNumber}
                                onFocus={() => onFocus("num_carte", cardNumberRef, layoutNumeric)}
                                inputProps={{ maxLength: 16 }}
                                inputRef={register({
                                    required: true
                                })}
                                ref={cardNumberRef}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={getInputValue("nom_carte")}
                                label="Nom du titulaire de la carte"
                                variant="outlined"
                                type="text"
                                name="nom_carte"
                                fullWidth
                                onClick={()=>scrollToRef(nameRef)}
                                autoComplete="no"
                                onFocus={() => onFocus("nom_carte", nameRef)}
                                onChange={onChangeInput}
                                inputRef={register({
                                    required: true
                                })}
                                ref={nameRef}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={getInputValue("date_exp")}
                                label="Mois / Année"
                                variant="outlined"
                                type="text"
                                name="date_exp"
                                fullWidth
                                onClick={()=>scrollToRef(expirationDateRef)}
                                autoComplete="no"
                                {...maskPropsDate}
                                onFocus={() => onFocus("date_exp", expirationDateRef, layoutNumeric)}
                                inputProps={{ maxLength: 6 }}
                                inputRef={register({
                                    required: true
                                })}
                                ref={expirationDateRef}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                value={getInputValue("cvc")}
                                label="Cryptogramme"
                                variant="outlined"
                                type="text"
                                name="cvc"
                                fullWidth
                                onClick={()=>scrollToRef(cvcRef)}
                                autoComplete="no"
                                {...maskPropsCsv}
                                onFocus={() => onFocus("cvc", cvcRef, layoutNumeric)}
                                inputProps={{ maxLength: 3 }}
                                inputRef={register({
                                    required: true
                                })}
                                ref={cvcRef}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.alignCenter}>
                            <div className={classes.wrapKeyBoard} style={!isVisible ? {display: 'none'} : {display: 'block', top: top}} ref={ref}>
                                <Keyboard
                                    keyboardRef={r => (keyboard.current = r)}
                                    onChangeAll={onChangeAll}
                                    layoutName={layoutName}
                                    onKeyPress={onKeyPress}
                                    inputName={inputName}
                                    layout={layoutTye}
                                    theme={"hg-theme-default myTheme1"}
                                    disableCaretPositioning={true}
                                    display= {{
                                        "{shift}": "⇧",
                                        "{bksp}": "⌫",
                                        "{alt}": ".?123",
                                        "{smileys}": "\uD83D\uDE03",
                                        "{shiftactivated}": "⇧",
                                        "{enter}": "return",
                                        "{altright}": ".?123",
                                        "{downkeyboard}": "🞃",
                                        "{space}": "⌴",
                                        "{default}": "ABC",
                                        "{back}": "⇦",
                                        "{ok}": "ok"
                                    }}
                                    preventMouseDownDefault={true}
                                />
                            </div>
                            <div className={classes.wrapBtns}>
                                <Footer banner={false} >
                                    {paymentMode === "facultatif" &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.buttonRed}
                                        href={`/detail-rendez-vous`}
                                    >
                                        Ignorer
                                    </Button>
                                    }
                                    <Button
                                        type="submit"
                                        title="Submit"
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                    >
                                        Prépayer
                                    </Button>
                                </Footer>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    )
}
