import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../utils/fetcher";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '55px!important'
    },
    text: {
        fontSize: '20px'
    },
    titleDialog: {
        color: theme.palette.text.primary
    },
    subTitleDialog: {
        color: theme.palette.text.secondary
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        padding: "30px"
    },
    DialogContent: {
        padding: "82px 162px 82px 162px!important",
        textAlign: "center"
    },
    textRed: {
        color: 'red',
        fontSize: "30px",
        marginTop: "30px"
    },
}));

export default function DialogRefresh(props) {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAuthenticate = useSelector(state => state.isAuthenticate);
    const subTextDefault = isAuthenticate ? "Pensez à retirer votre carte vitale." : "Vous allez être automatiquement déconnecté."

    const handleClose = () => {
        setTimeout(() => {
            setOpen(false);
            dispatch(logout());
        }, 3000);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent className={classes.DialogContent}>
                    <DialogContentText id="alert-dialog-description" className={classes.text}>
                        <Typography variant="h1" component="div" color="primary" align="center">
                            {props.text}<br/>
                        </Typography>
                        <Typography variant="body1" component="div" className={classes.textRed}>
                            {props.subText ? props.subText : subTextDefault }
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
