import React from 'react';
import {useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    wrapLoader: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "#ffffff90",
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99
    }
}));

export default function Loader() {
    const classes = useStyles();
    const isLoad = useSelector(state => state.loading);

    return (
        <>{isLoad && <div className={classes.wrapLoader}><CircularProgress /></div>}</>
    )
}
