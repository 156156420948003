import React from 'react';
import {useSelector} from "react-redux";
import ListAppointment from "../components/layout/ListAppointment";
import OneAppointment from "../components/layout/OneAppointment";

export default function Appointment() {
    const appointments = useSelector(state => state.appointments);

    return (
        <>
            {appointments.length > 1 ?
                <ListAppointment appointments={appointments} />
                :
                <OneAppointment />
            }
        </>
    )
}
