import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Carousel from "react-material-ui-carousel";
import {useSelector} from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import DetailFile from "../components/layout/DetailFile";
import Alert from "@material-ui/lab/Alert";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    img: {
        fontSize: '76px!important'
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        padding: "23px",
        background: theme.palette.secondary.main
    },
    marginAuto: {
        margin: 'auto'
    },
    wrapCards: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    alignCenter: {
        textAlign: 'center'
    },
    button: {
        padding: '6px 62px',
    },
    caroussel: {
        maxWidth: '955px',
        margin: 'auto',
        overflow: "inherit",
        width: "100%"
    },
    white: {
        color: 'white'
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    p0: {
        padding: 0
    },
    iconCheckbox: {
        fontSize: 40
    },
    iconCheckboxChecked: {
        fontSize: 40,
        color: theme.palette.primary.main
    },
    wrapBtn: {
        justifyContent: "flex-end",
        padding: 0
    },
    banner: {
        boxShadow: 'none'
    },
    cardMedia: {
        background: "white",
        borderRadius: "50%",
        width: "153px",
        height: "153px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto"
    },
    alert: {
        fontSize: "20px",
        alignItems: "center"
    },
}));

export default function MyFiles() {
    const classes = useStyles();
    const listFiles = useSelector(state => state.files ? state.files : []);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState({});

    const list = [];
    if (listFiles) {
        listFiles.forEach((item, key) => {
            if (key % 4 === 0) {
                list.push(listFiles.slice(key, key+4));
            }
        });
    }

    const showDetail = file => {
        setFile(file);
        setOpen(true);
    }

    return (
        <>
            {open && <DetailFile open={open} onClose={(isOpen)=>setOpen(isOpen)} file={file} />}
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Mes documents</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Retrouvez tous vos documents disponibles</Typography>
            </div>
            <div className="wrap-content-page">
                <div>
                    {list.length > 0 ? (
                            <Carousel navButtonsAlwaysVisible={true} indicators={false} autoPlay={false} className="caroussel"
                                navButtonsProps={{className:"CarouselButtons"}}
                            >

                                {list.map((elet, index) => (
                                    <Card raised className={classes.banner} key={`item-${index}`}>
                                        <Grid container spacing={0} className="BannerGrid">
                                            {elet.map((item, key) => (
                                                <Grid className={classes.bloc} item xs={3} key={`sub-${key}`}>
                                                    <Card className={classes.card}>
                                                        <CardActionArea onClick={()=>showDetail(item)}>
                                                            <CardMedia className={classes.cardMedia}>
                                                                <DescriptionIcon className={classes.img} color="secondary" />
                                                            </CardMedia>
                                                            <CardContent className={classes.p0}>
                                                                <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                                                    {item.nom_document_file_rdv}
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions className={classes.wrapBtn}>
                                                            </CardActions>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Card>
                                ))}

                            </Carousel>
                        ):
                        <Alert className={classes.alert} severity="error">Aucun document n'est disponible</Alert>
                    }
                </div>
            </div>
            <Footer banner={false}>
                <div className={classes.alignCenter}>
                    <Button
                        type="submit"
                        title="Submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        href="#/demande-documents"
                    >
                        J'ai besoin d'un nouveau document
                    </Button>
                </div>
            </Footer>
        </>
    )
}
