import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Grid from '@material-ui/core/Grid';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { createHashHistory } from 'history';
import { setCurrentPatient, setType } from '../actions';
import { getPageToRedirect } from '../utils/fetcher';
import Footer from '../components/Common/Footer';
import { parseArrayForCarousel } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    img: {
        fontSize: '153px!important',
        color: 'white',
    },
    icon: {
        fontSize: '55px!important',
    },
    titleColor: {
        color: theme.palette.text.primary, // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary,
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
    },
    marginAuto: {
        margin: 'auto',
    },
    wrapCards: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    alignCenter: {
        textAlign: 'center',
    },
    button: {
        padding: '6px 62px',
        marginBottom: 50,
    },
    white: {
        color: 'white',
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    p0: {
        padding: 0,
    },
    iconCheckbox: {
        fontSize: 40,
    },
    iconCheckboxChecked: {
        fontSize: 40,
        color: theme.palette.primary.main,
    },
    wrapBtn: {
        justifyContent: 'flex-end',
        padding: 0,
    },
    banner: {
        boxShadow: 'none',
    },
}));

export default function ListPersons() {
    const history = createHashHistory();
    const classes = useStyles();
    const listPatients = useSelector((state) => (state.patients ? state.patients : []));
    const dispatch = useDispatch();

    const togglePerson = (item) => {
        dispatch(setCurrentPatient(item));
        if (item.rdv_today === 0) {
            dispatch(setType('noAppointment'));
        } else if (!item.isTreated && item.rdv_today === 1) {
            dispatch(setType('haveAppointment'));
        }
        const goTo = getPageToRedirect();
        history.push(goTo);
    };

    let list = parseArrayForCarousel(listPatients, 4);

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    Votre profil
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Choisissez qui est concerné par votre demande
                </Typography>
            </div>
            <div className="wrap-content-page">
                <div className="carouselWrap">
                    {list.length > 0 && (
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            indicators={false}
                            autoPlay={false}
                            className="caroussel"
                            navButtonsProps={{ className: 'CarouselButtons', disabled: (list.length <= 1) }}
                            navButtonsWrapperProps={{ className: 'CarouselButtonsWrapper' }}
                        >
                            {list.map((elet, index) => (
                                <Card raised className={classes.banner} key={`item-${index}`}>
                                    <Grid container spacing={0} className="BannerGrid">
                                        {elet.map((item, key) => (
                                            <Grid item key={`sub-${key}`}>
                                                <Card className={classes.card} onClick={() => togglePerson(item)}>
                                                    <CardActionArea>
                                                        <CardMedia>
                                                            <AccountCircleIcon className={classes.img} color="primary" />
                                                        </CardMedia>
                                                        <CardContent className={classes.p0}>
                                                            <Typography gutterBottom variant="body2" className={classes.text}>
                                                                {item.nom_patient} {item.prenom_patient}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Card>
                            ))}
                        </Carousel>
                    )}
                </div>
            </div>
            <Footer banner={false} isShowDialogOnBack={true} />
        </>
    );
}
