import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {createHashHistory} from 'history';
import {setDoctor} from "../actions";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    img: {
        fontSize: '153px!important',
        color: 'white'
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    card: {
        textAlign: 'center',
        margin: '20px',
        background: theme.palette.secondary.main,
        cursor: "pointer"
    },
    marginAuto: {
        margin: 'auto'
    },
    wrapCards: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    alignCenter: {
        textAlign: 'center'
    },
    button: {
        padding: '6px 62px',
    },
    caroussel: {
        margin: 'auto',
        overflow: "inherit"
    },
    white: {
        color: 'white'
    },
    text: {
        fontSize: 20,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    px0: {
        paddingTop: 0,
        paddingBottom: 0
    },
    iconCheckbox: {
        fontSize: 40
    },
    iconCheckboxChecked: {
        fontSize: 40,
        color: theme.palette.primary.main
    },
    wrapBtn: {
        justifyContent: "flex-end",
        padding: 0
    },
    banner: {
        boxShadow: 'none'
    },
    wrapPage: {
        alignItems: 'center',
        flex: 1,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column"
    },
    cardMedia: {
        paddingTop: "10px",
    },
    bloc: {
        width: "100%"
    }
}));

export default function LastDoctor() {
    const history = createHashHistory();
    const classes = useStyles();
    const doctor = useSelector(state => state.doctors.last[0] ? state.doctors.last[0] : []);
    const dispatch = useDispatch();

    const toggle = item => {
        dispatch(setDoctor(item));
        history.push('/motifs');
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Souhaitez-vous revoir le {doctor.toubib} ?</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Choisissez ce praticien ou cliquez sur "choisir un autre praticien"</Typography>
            </div>
            <div className={classes.wrapPage}>
                <Grid className={classes.bloc} item xs={4}>
                    <Card className={classes.card} onClick={()=>toggle(doctor)}>
                        <CardActionArea>
                            <CardMedia className={classes.cardMedia}>
                                {doctor.photo_praticien ? <img width="153" alt={doctor.toubib} src={doctor.photo_praticien}/> :
                                    <AccountCircleIcon className={classes.img} color="primary"/>
                                }
                            </CardMedia>
                            <CardContent className={classes.px0}>
                                <Typography gutterBottom variant="body2" component="p" className={classes.text}>
                                    {doctor.toubib}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions className={classes.wrapBtn}>
                            <FormControlLabel
                                control={<Checkbox
                                    name={`${doctor.guid_praticien}`}
                                    value={doctor.guid_praticien}
                                    onChange={()=>toggle(doctor)}
                                />}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            </div>
            <Footer banner={false}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    href="#/praticiens"
                >
                    Choisir un autre praticien
                </Button>
            </Footer>
        </>
    )
}
