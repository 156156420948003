import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { createHashHistory } from 'history';
import DialogConfirmInsertCard from './DialogConfirmInsertCard';

const useStyles = makeStyles((theme) => ({
    button: {
        height: '100%',
        fontSize: 40,
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 0',
    },
    iconInfo: {
        marginRight: '16px',
        fontSize: '45px',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px 50px',
        color: theme.palette.text.primary,
    },
    wrapInfo: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2%',
    },
    icon: {
        fontSize: '55px!important',
    },
}));

export default function Footer(props) {
    const classes = useStyles();
    const { additionInfo } = useSelector((state) => state.settings);
    const history = createHashHistory();
    const [showDialog, setShowDialog] = useState(false);

    const goToBack = () => {
        history.goBack();
    };

    return (
        <Grid container className={classes.root}>
            {props.isShowDialogOnBack && showDialog && <DialogConfirmInsertCard onCloseDialog={setShowDialog} />}
            <Button variant="contained" className={classes.buttonRed} color="secondary" onClick={props.isShowDialogOnBack ? () => setShowDialog(true) : goToBack}>
                Retour
            </Button>
            {props.banner && (
                <div className={classes.wrapInfo}>
                    <Grid>
                        {additionInfo && (
                            <Typography variant="h4" component="h4" gutterBottom color="primary" className={classes.info}>
                                <InfoOutlinedIcon className={classes.iconInfo} />
                                <span dangerouslySetInnerHTML={{ __html: additionInfo }} />
                            </Typography>
                        )}
                    </Grid>
                </div>
            )}
            {props.children}
        </Grid>
    );
}

Footer.propTypes = {
    banner: PropTypes.bool,
    children: PropTypes.any,
    isShowDialogOnBack: PropTypes.bool,
};
