import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '55px!important'
    },
    text: {
        fontSize: '20px'
    },
    button: {
        padding: '6px 50px',
    },
    titleDialog: {
        color: theme.palette.text.primary,
        fontWeight: "bold"
    },
    subTitleDialog: {
        color: theme.palette.text.secondary,
        fontSize: "30px"
    },
    dialogActions: {
        justifyContent: "center",
        padding: "30px"
    },
    DialogContent: {
        padding: "45px 50px 4px 50px!important"
    },
    alert: {
        fontSize: "20px",
        marginBottom: "20px",
        alignItems: "center"
    },
}));

export default function DialogConfirmInsertCard(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.onCloseDialog(false);
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent className={classes.DialogContent}>
                    <DialogContentText id="alert-dialog-description" className={classes.titleDialog}>
                        <Typography variant="h1" component="h1" color="primary" align="center">
                            Vous avez déjà inséré votre carte vitale
                        </Typography>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className={classes.subTitleDialog}>
                        <Typography variant="p" component="p" align="center">
                            Enlevez votre carte vitale si vous souhaitez vous déconnecter
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        onClick={handleClose}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
