import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {printPaper} from "../utils/fetcher";
import DialogConfirmClose from "../components/Common/DialogConfirmClose";
import DialogRefresh from "../components/Common/DialogRefresh";


const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: '50px',
        padding: '6px 62px',
    },
    buttonSecondary: {
        marginTop: '30px',
        padding: '6px 62px',
        margin: "auto"
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    wrapBtns: {
        display: 'flex',
        justifyContent: "space-between"
    },
    mt30: {
        marginTop: "30px"
    },
    alignCenter: {
        textAlign: "center"
    },
    maxFullWidth: {
        maxWidth: "100%"
    }
}));

export default function Summary() {
    const classes = useStyles();
    const appointment = useSelector(state => state.currentAppointment);
    const isShowQrCode = useSelector(state => state.settings.isShowQrCode);
    const message = useSelector(state => state.messageWithCode);
    const type = useSelector(state => state.type);
    const dataPrint = useSelector(state => state.dataPrint);
    const dispatch = useDispatch();

    const print = () => {
        const data = {
            type_format: "ticket",
            date: appointment.date_prise_rendez_vous,
            hour: appointment.heure_aff,
            ticket_number: appointment.num_ticket && appointment.num_ticket,
            abbr_civility_doctor: appointment.abreviation_civilite,
            name_doctor: appointment.nom_praticien,
            lastname_patient: appointment.nom_patient,
            firstname_patient: appointment.prenom_patient,
            instructions_waiting_room: appointment.infosalle && appointment.infosalle.consigne_borne,
            recommendation_pattern: appointment.infosalle && appointment.preconisation_motif_borne_rdv,
            ...dataPrint
        }
        dispatch(printPaper(data));
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Votre rendez-vous</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Voici un récapitulatif de votre rendez-vous</Typography>
            </div>
            <div className="wrap-content-page">
                {type === "refresh" && <DialogConfirmClose />}
                {type === "quitBorne" && <DialogRefresh text="Merci de votre viste." />}
                <div className={classes.alignCenter}>
                    <div>
                        <Typography variant="h1" component="h1" color="primary" paragraph>
                            Date et horaire : {appointment.rdvseullite} avec {appointment.Infodocteur}
                        </Typography>
                        {appointment.infosalle && appointment.infosalle.message_salle &&
                        <Typography variant="h3" component="h3" color="primary" paragraph>
                            <span dangerouslySetInnerHTML={{ __html: appointment.infosalle.message_salle }} />
                        </Typography>
                        }
                    </div>
                </div>
                <div className={classes.wrapBtns}>
                    {isShowQrCode === "1" &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        href="#/generate-qrcode"
                    >
                        Générer un QR Code
                    </Button>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={print}
                    >
                        Imprimer mon ticket
                    </Button>
                </div>
                {message && message.code === 'print' &&
                <Typography color="success" className={classes.mt30}>
                    {message.text}
                </Typography>
                }
            </div>
        </>
    )
}
