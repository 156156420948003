import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Footer from "../components/Common/Footer";
import {createHashHistory} from 'history';
import {setCurrentLocation} from "../actions";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    button: {
        padding: '6px 36px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    fullHeight: {
        flex: 1,
        paddingTop: '30px',
        flexDirection: "column",
        display: "flex",
        alignItems: "center"
    },
    select: {
        width: "783px"
    },
}));

export default function LocationsAppointment() {
    const classes = useStyles();
    const [location, setLocation] = useState({});
    const listLocations = useSelector(state => state.locations ? state.locations : []);
    const dispatch = useDispatch();
    const history = createHashHistory();

    const send = () => {
        dispatch(setCurrentLocation(location));
        history.push('/disponibilites');
    };

    const isChanging = (e) => {
        setLocation(e.target.value);
    }

    const renderSelect = (value) => {
        if(Object.values(value).length === 0 && listLocations.length === 1){
            setLocation(listLocations[0])
            return listLocations[0].nom_synthese_lieu;
        }else if(Object.values(value).length === 0){
            return "Sélectionnez le lieu";
        }
        return value.nom_synthese_lieu;
    }

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>
                    Prendre mon rendez-vous
                </Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>
                    Choisissez le lieu de ma consultation
                </Typography>
            </div>
            <div className="wrap-content-page">
                <div className={classes.fullHeight}>
                    {listLocations &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Lieux</InputLabel>
                            <Select
                                labelId="select-question-label"
                                id="select-question"
                                value={location}
                                onChange={isChanging}
                                
                                label="Lieu"
                                className={classes.select}
                                renderValue={renderSelect}
                            >
                                {listLocations.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item.libelle}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </div>
                <div className={classes.wrapBtn}>
                    <Footer banner={false}>
                        <Button
                            type="submit"
                            title="Submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={Object.values(location).length === 0}
                            onClick={send}
                        >
                            Envoyer
                        </Button>
                    </Footer>
                </div>
            </div>
        </>
    )
}
