import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useDispatch} from "react-redux";
import {logout} from "../../utils/fetcher";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '55px!important'
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px 50px',
        color: theme.palette.text.primary
    },
    text: {
        fontSize: '20px'
    },
    button: {
        padding: '6px 50px',
    },
    textRed: {
        color: 'red'
    },
    titleDialog: {
        color: theme.palette.text.primary
    },
    subTitleDialog: {
        color: theme.palette.text.secondary
    },
    dialogActions: {
        display: "flex",
        justifyContent: "space-between",
        padding: "30px"
    },
    DialogContent: {
        padding: "82px 162px 52px 162px!important"
    },
    alert: {
        fontSize: "20px",
        marginBottom: "20px",
        alignItems: "center"
    },
}));

export default function DialogConfirmClose() {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isQuit, setIsQuit] = React.useState(false);

    const handleClose = () => {
        setIsQuit(true)
        setTimeout(() => {
            setOpen(false);
            dispatch(logout());
            }, 3000);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogContent className={classes.DialogContent}>
                    {isQuit ?
                        <Alert severity="error" className={classes.alert}>Pensez à enlever votre carte du lecteur</Alert>
                        :
                        <DialogContentText id="alert-dialog-description" className={classes.text}>
                            <Typography variant="h1" component="h1" color="primary" align="center">
                                Souhaitez-vous autre chose ?
                            </Typography>
                        </DialogContentText>
                    }
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        onClick={handleClose}
                    >
                        Quitter
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.buttonRed}
                        color="secondary"
                        href="#/menu"
                    >
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
