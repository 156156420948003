import React from 'react';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import Footer from "../components/Common/Footer";

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '6px 62px',
    },
    icon: {
        fontSize: '55px!important'
    },
    titleColor: {
        color: theme.palette.text.primary // or theme.palette.primary.main
    },
    subTitleColor: {
        color: theme.palette.text.secondary
    },
    alignCenter: {
        textAlign: 'center'
    },
    wrapBtns: {
        display: 'flex',
        justifyContent: "space-around",
    },
    alignLeft: {
        textAlign: 'left'
    },
    mb50: {
        marginBottom: 50
    },
    buttonRed: {
        background: theme.palette.error.secondary,
        padding: '6px',
        color: theme.palette.text.primary
    },
}));

export default function ValidConsultation() {
    const sum = useSelector(state => state.currentAppointment.montant_payable ?state.currentAppointment.montant_payable : "");
    const classes = useStyles();
    const paymentMode = useSelector(state => state.settings.isRequiredPrepayment);

    return (
        <>
            <div className="head-title">
                <Typography variant="h1" component="h1" className={classes.titleColor}>Validation de votre consultation</Typography>
                <Typography variant="h4" component="h4" className={classes.subTitleColor}>Votre carte bancaire ne sera débitée qu'une fois votre consultation terminée. </Typography>
            </div>
            <div className="wrap-content-page">
                <div className={classes.alignCenter}>

                    <Typography variant="h1" component="h1" color="primary" paragraph className={classes.mb50}>
                        Le montant à créditer est de {sum} EUR
                    </Typography>
                    <div className={classes.alignLeft}>
                        <Typography variant="body2" component="body2" color="primary" paragraph display="block" className={classes.mb50}>
                            Le prépaiement valide votre consultation, le montant débuté en fin de consultation
                            correspondra à vos soins et status (tiers payant…).
                        </Typography>

                        <Typography variant="body2" component="body2" color="primary" paragraph display="block">
                            Ce montant final ne pourra pas dépasser celui du prépaiement effectué ici, le restant
                            sera annulé et non prélevé.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.wrapBtns}>
                <Footer banner={false} >
                    {paymentMode === "facultatif" &&
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonRed}
                        href={`/detail-rendez-vous`}
                    >
                        Ignorer
                    </Button>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        href="#/prepaiement"
                    >
                        Prépayer
                    </Button>
                </Footer>
            </div>
        </>
    )
}
